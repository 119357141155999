import React from 'react';
import './ArtModal.css';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  underlinedHeading: {
    fontFamily: 'Montserrat, sans-serif',
    marginBottom: '10px',
    fontSize: '28px',
    color: 'black',
    position: 'relative',
    textDecoration: 'none',
    display: 'inline-block',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: 2,
      width: '30%',
      borderBottom: '4px solid #FFD700 !important',
    },
  },
}));

const ArtModal = ({ art, isKannada, onClose }) => {
  const styles = useStyles();

  if (!art) return null;

  // Function to handle click outside the modal content
  const handleCloseModal = (e) => {
    // Check if the click is outside the modal content
    if (e.target === e.currentTarget) {
      onClose(); // Call onClose function passed as prop
    }
  };

  return (
    <div className="art-modal" onClick={handleCloseModal}>
      <div className="art-modal-content">
        <span className="art-modal-close" onClick={onClose}>&times;</span>
        <img className="art-modal-image" src={art.image} alt={isKannada ? art.name : art.name} />
        <div className="art-modal-details">
          <h2 className={styles.underlinedHeading}>{isKannada ? art.name : art.name}</h2>
          <p className="art-modal-description">{isKannada ? art.description : art.description}</p>
        </div>
      </div>
    </div>
  );
};

export default ArtModal;
