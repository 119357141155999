import React from 'react';
import PropTypes from 'prop-types';

export const IconComponentNode = ({ className, color }) => (
  <svg
    className={className}
    fill='#f1981a'
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24px"
    height="24px"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M12 2L2 12h3v7h6v-5h2v5h6v-7h3z" />
  </svg>
);

IconComponentNode.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

IconComponentNode.defaultProps = {
  className: '',
  color: 'black',
};
