// src/data.js

import chibluIdli from '../../Assets/Artsandcusine/ChibluIdli.jpeg';
import ganjifaCards from '../../Assets/Artsandcusine/GanjifaCards.jpeg';
import maddurVade from '../../Assets/Artsandcusine/MaddurVade.jpeg';
import mandyaBaduta from '../../Assets/Artsandcusine/MandyaBaduta.jpeg';
import melukotePuliyogareandPongal from '../../Assets/Artsandcusine/MelukotePuliyogareandPongal.jpeg';
import pooja_Kunitha from '../../Assets/Artsandcusine/Pooja_Kunitha.jpeg';
import ragiMuddeandUppuSaru from '../../Assets/Artsandcusine/RagiMuddeandUppuSaru.jpeg';
import ragiRottiwithUchchelluChutney from '../../Assets/Artsandcusine/RagiRottiwithUchchelluChutney.jpeg';
import rangaKunita from '../../Assets/Artsandcusine/RangaKunita.jpeg';
import sobanePada from '../../Assets/Artsandcusine/SobanePada.jpeg';
import somana_Kunitha from '../../Assets/Artsandcusine/Somana_Kunitha.jpeg';
import veeragase from '../../Assets/Artsandcusine/Veeragase.jpeg';

const data = {
    en: {
      arts: [
        {
            name: "Veeragase",
            description: "Veeragase, a traditional folk dance-drama of Mandya, vividly narrates tales of heroism and divine intervention, focusing on Lord Veerabhadra, a fierce form of Shiva. Celebrated during festivals like Maha Shivaratri, it features vigorous movements and percussion beats from instruments like the 'chande' and 'dollu'. Performers don bright costumes and symbolic props like swords and shields, enhancing the martial theme. Incorporating storytelling and vocal renditions of legends, Veergase blends dance, music, and drama, preserving Mandya's rich folklore. This vibrant art form fosters community spirit, ensuring the enduring legacy of Mandya's cultural heritage.",
            image: veeragase,
        },
        {
            name: "Somana Kunita",
            description: "Somana Kunita is a popular traditional dance in Karnataka, especially in the Tumkur, Chitradurga, Mandya, and Hassan districts. This dance, performed as part of the worship of Goddess Soma, features performers wearing demon masks and colorful decorations. It is believed that Soma dances to entertain and please the goddess. The dance, accompanied by folk songs, holds a special place in rural culture, reflecting the villagers' deep devotion. Initially rooted in local tradition, Somana Kunita has now gained international recognition, showcasing the rich cultural heritage of Karnataka.",
            image: somana_Kunitha,
        },
        {
            name: "Ranga Kunita",
            description: "Ranga Kunitha is a traditional dance widely performed in Mandya district and occasionally in Bangalore, Mysore, Hassan, and Chikkamagaluru districts. Also known as the harvest dance, it is performed in front of the village deity's temple. Dancers wear bells on their legs and colorful attire around their heads and waists. Instruments like Tamate, Nagari, and Parachi (Tharasi) accompany the dance. The number of dancers is usually even, performing steps in sequences of three, five, or seven, with the five-step dance called \"Thiruni Kunita.\" The dance, featuring songs about the village deity and humor, continues until late at night. After the festival, the pole, kalasa, and instruments are discarded in a stream.",
            image: rangaKunita,
        },
        {
            name: "Sobane Pada",
            description: "Sobane Pada is a traditional folk art form in Karnataka, particularly popular in rural areas. It involves singing devotional songs, often by women, in praise of deities during auspicious occasions like childbirth and weddings. The songs, passed down through generations, are rich in cultural and religious themes, reflecting the community's values and beliefs. Sobane Pada is typically performed in groups, creating a harmonious and spiritually uplifting experience. This folk art not only preserves the region's cultural heritage but also strengthens communal bonds, celebrating life's significant events through music and devotion.",
            image: sobanePada,
        },
        {
            name: "Pooja Kunitha",
            description: "Pooja Kunitha is a traditional dance associated with Shakti deities, prevalent in Bangalore, Mandya, Kolar, Chikkaballapur, Tumkur, and Mysore districts. During festivals, the village deity is symbolically brought out in a ritual known as 'pooja.' Dancers wear masks of the deity and perform with a bamboo structure,' about 5 feet long and 4 feet wide. The structure is decorated with brass or silver kalasas, ornaments, and flowers. Devotees adorn this and dance energetically, showcasing their devotion. A bronze or brass pin at the bottom of this structure helps stabilize it when carried on the head.",
            image: pooja_Kunitha,
        },
        {
            name: "Ganjifa Art",
            description: "Ganjifa is a traditional card game that enjoyed widespread popularity among kings, courtiers, and the general masses, similar to Pachisi and Chess. The Indian Ganjifa cards are renowned for their vibrant colors and intricate paintings. Introduced to India by Mughal emperors in the early 16th century, the cards spread across the country in various forms. The original Mughal Ganjifa evolved into the Hindu version, known as Dashavatara Ganjifa. This art form was particularly well-practiced in Srirangapatna, showcasing India's rich cultural heritage through its exquisite design and historical significance.",
            image: ganjifaCards,
        },
      ],
      cusine: [
        {
            name: "Ragi Mudde and Uppu Saru",
            description: "Ragi Mudde and Uppu Saru are quintessential dishes of Karnataka cuisine. Ragi Mudde, a dense ball made from finger millet flour, is often enjoyed with Uppu Saru, a tangy broth flavored with spices. Traditionally, Ragi Mudde is dipped in melted butter or ghee before being savored with Uppu Saru, enhancing its taste and texture. This combination not only provides a hearty and nutritious meal but also highlights the cultural significance of local ingredients and culinary practices in Karnataka's gastronomic heritage.",
            image: ragiMuddeandUppuSaru,
        },
        {
            name: "Madduru Vade",
            description: "Madduru Vade is a famous South Indian snack originating from Maddur in Mandya. It is a crispy and savory deep-fried fritter made from a batter of rice flour, semolina, all-purpose flour, onions, curry leaves, and spices. The dough is shaped into flat discs and fried until golden brown, resulting in a crunchy exterior and a soft, flavorful interior. Maddur Vade is known for its unique taste and aroma, often enjoyed as a tea-time snack or as part of festive meals. Its popularity extends beyond Karnataka, making it a beloved treat across South India.",
            image: maddurVade,
        },
        {
            name: "Chiblu Idli",
            description: "Chiblu Idli is a renowned delicacy from Halaguru in Malavalli Taluk, Karnataka. It stands out for its unique preparation method where the idli batter is steamed inside bamboo bowls, imparting a distinct flavor and texture. These idlis are known for their exceptional taste and pleasant aroma, attracting food enthusiasts far and wide. The bamboo lends a subtle earthy note to the idlis, complementing their soft and fluffy texture. Chiblu Idli is cherished not only for its traditional roots but also for its delightful culinary experience that captures the essence of local Karnataka cuisine.",
            image: chibluIdli,
        },
        {
            name: "Ragi Rotti with Uchchellu Chutney",
            description: "Ragi Rotti with Uchchellu Chutney is a nutritious dish made from ragi grain, popular in Mandya district, Karnataka. Ragi Rotti is a flatbread prepared from ragi flour, mixed with onions, green chilies, and spices, then cooked on a griddle until crisp. Uchchellu Chutney, made from roasted sesame seeds, coconut, and spices, complements the Ragi Rotti with its nutty and spicy flavor. This dish is valued not only for its taste but also for its health benefits, as ragi is rich in fiber and nutrients. ",
            image: ragiRottiwithUchchelluChutney,
        },
        {
            name: "Melukote Puliyogare and Pongal",
            description: "Melukote is also renowned for its traditional dishes like Puliyogare and Pongal. Puliyogare, a tangy and spicy tamarind-flavored rice dish, is a favorite among locals and visitors alike for its bold flavors and aromatic spices. Pongal, on the other hand, is a comforting dish made from rice and lentils, typically seasoned with black pepper, cumin, and ghee, offering a creamy and wholesome taste. Both dishes are integral to the cultural and culinary heritage of Melukote, reflecting the region's rich traditions and the artistry of South Indian cuisine.",
            image: melukotePuliyogareandPongal,
        },
        {
            name: "Mandya Baduta",
            description: "Mandya Baduta is a celebrated culinary delight from Mandya district, highlighting local non-vegetarian specialties. Bannur Mutton Biryani, known for its fragrant basmati rice and tender mutton cooked with aromatic spices, captivates with its rich flavors and textures. Nati Koli curry, a traditional chicken curry, is cherished for its robust taste and hearty appeal, often enjoyed with Ragi Mudde, a nutritious millet-based staple.",
            image: mandyaBaduta,
        },
      ]
    },
    kn: {
      arts: [
        {
            name: "ವೀರಗಾಸೆ",
            description: "ಮಂಡ್ಯದ ಸಾಂಪ್ರದಾಯಿಕ ಜಾನಪದ ನೃತ್ಯ-ನಾಟಕವಾದ ವೀರಗಾಸೆಯು ಶಿವನ ಉಗ್ರ ರೂಪವಾದ ವೀರಭದ್ರನನ್ನು ಕೇಂದ್ರೀಕರಿಸಿ, ವೀರ ಮತ್ತು ದೈವೀಯ ಕಥೆಗಳನ್ನು ಸ್ಪಷ್ಟವಾಗಿ ನಿರೂಪಿಸುತ್ತದೆ. ಮಹಾ ಶಿವರಾತ್ರಿಯಂತಹ ಹಬ್ಬಗಳಲ್ಲಿ ಆಚರಿಸಲ್ಪಡುವ, ಇದು 'ಚಂಡೆ' ಮತ್ತು 'ಡೊಳ್ಳು' ನಂತಹ ವಾದ್ಯಗಳಿಂದ ಹುರುಪಿನ ಚಲನೆಗಳು ಮತ್ತು ತಾಳವಾದ್ಯಗಳನ್ನು ಒಳಗೊಂಡಿರುತ್ತದೆ. ಪ್ರದರ್ಶಕರು ಪ್ರಕಾಶಮಾನವಾದ ವೇಷಭೂಷಣಗಳನ್ನು ಮತ್ತು ಕತ್ತಿಗಳು ಹಾಗು ಗುರಾಣಿಗಳಂತಹ ಸಾಂಕೇತಿಕ ರಂಗಪರಿಕರಗಳನ್ನು ಧರಿಸಿ, ಸಮದ ರೂಪಕಗಳನ್ನು ಅನ್ನು ಹೆಚ್ಚಿಸುತ್ತದೆ. ದಂತಕಥೆಗಳ ಕಥಾನಕ ಮತ್ತು ಗಾಯನ ನಿರೂಪಣೆಯನ್ನು ಸೇರಿಸಿ, ವೀರಗಾಸೆ ನೃತ್ಯ, ಸಂಗೀತ ಮತ್ತು ನಾಟಕವನ್ನು ಸಂಯೋಜಿಸಿ ಮಂಡ್ಯದ ಶ್ರೀಮಂತ ಜಾನಪದವನ್ನು ಉಳಿಸುತ್ತದೆ. ಈ ರೋಮಾಂಚಕ ಕಲಾ ಪ್ರಕಾರವು ಸಮುದಾಯದ ಮನೋಭಾವವನ್ನು ಬೆಳೆಸಿ, ಮಂಡ್ಯದ ನಿರಂತರ ಸಾಂಸ್ಕೃತಿಕ ಪರಂಪರೆಯನ್ನು ಖಾತ್ರಿಪಡಿಸುತ್ತದೆ.",
            image: veeragase,
        },
        {
            name: "ಸೋಮನ ಕುಣಿತ",
            description: "ಸೋಮನ ಕುಣಿತವು ಕರ್ನಾಟಕದಲ್ಲಿ, ವಿಶೇಷವಾಗಿ ತುಮಕೂರು, ಚಿತ್ರದುರ್ಗ, ಮಂಡ್ಯ ಮತ್ತು ಹಾಸನ ಜಿಲ್ಲೆಗಳಲ್ಲಿ ಜನಪ್ರಿಯ ಸಾಂಪ್ರದಾಯಿಕ ನೃತ್ಯವಾಗಿದೆ. ಸೋಮಾ ದೇವಿಯ ಆರಾಧನೆಯ ಭಾಗವಾಗಿ ಪ್ರದರ್ಶಿಸಲಾದ ಈ ನೃತ್ಯವು ರಾಕ್ಷಸ ಮುಖವಾಡಗಳನ್ನು ಮತ್ತು ವರ್ಣರಂಜಿತ ಅಲಂಕಾರಗಳನ್ನು ಧರಿಸಿರುವ ಕಲಾವಿದರನ್ನು ಒಳಗೊಂಡಿರುತ್ತದೆ. ದೇವತೆಗಳನ್ನು ಮನರಂಜಿಸಲು ಮತ್ತು ಮೆಚ್ಚಿಸಲು ಸೋಮ ನೃತ್ಯ ಮಾಡುತ್ತಾನೆ ಎಂದು ನಂಬಲಾಗಿದೆ. ಜಾನಪದ ಗೀತೆಗಳೊಂದಿಗೆ ನೃತ್ಯವು ಗ್ರಾಮೀಣ ಸಂಸ್ಕೃತಿಯಲ್ಲಿ ವಿಶೇಷ ಸ್ಥಾನವನ್ನು ಹೊಂದಿದ್ದು ಇದು ಹಳ್ಳಿಗರಲ್ಲಿರುವ ಆಳವಾದ ಭಕ್ತಿಯನ್ನು ಪ್ರತಿಬಿಂಬಿಸುತ್ತದೆ. ಆರಂಭದಲ್ಲಿ ಸ್ಥಳೀಯ ಸಂಪ್ರದಾಯದಲ್ಲಿ ಬೇರೂರಿದ್ದ ಸೋಮನ ಕುಣಿತ ಈಗ ಅಂತಾರಾಷ್ಟ್ರೀಯ ಮನ್ನಣೆ ಗಳಿಸಿದ್ದು, ಕರ್ನಾಟಕದ ಶ್ರೀಮಂತ ಸಾಂಸ್ಕೃತಿಕ ಪರಂಪರೆಯನ್ನು ಪ್ರದರ್ಶಿಸುತ್ತಿದೆ.",
            image: somana_Kunitha,
        },
        {
            name: "ರಂಗ ಕುಣಿತ",
            description: "ರಂಗ ಕುಣಿತವು ಮಂಡ್ಯ ಜಿಲ್ಲೆಯಲ್ಲಿ ಮತ್ತು ಸಾಂದರ್ಭಿಕವಾಗಿ ಬೆಂಗಳೂರು, ಮೈಸೂರು, ಹಾಸನ ಮತ್ತು ಚಿಕ್ಕಮಗಳೂರು ಜಿಲ್ಲೆಗಳಲ್ಲಿ ವ್ಯಾಪಕವಾಗಿ ಪ್ರದರ್ಶನಗೊಳ್ಳುವ ಸಾಂಪ್ರದಾಯಿಕ ನೃತ್ಯವಾಗಿದೆ. ಸುಗ್ಗಿಯ ನೃತ್ಯ ಎಂದೂ ಕರೆಯಲ್ಪಡುವ ಈ ನೃತ್ಯವನ್ನು ಗ್ರಾಮ ದೇವತೆಯ ದೇವಾಲಯದ ಮುಂದೆ ಪ್ರದರ್ಶಿಸಲಾಗುತ್ತದೆ. ನರ್ತಕರು ತಮ್ಮ ಕಾಲುಗಳ ಮೇಲೆ ಗಂಟೆಗಳನ್ನು, ತಲೆ ಮತ್ತು ಸೊಂಟದ ಸುತ್ತಲೂ ವರ್ಣರಂಜಿತ ಉಡುಪನ್ನು ಧರಿಸುತ್ತಾರೆ. ತಮಟೆ, ನಗಾರಿ, ಮತ್ತು ಪರಚಿ (ತರಸಿ) ಮುಂತಾದ ವಾದ್ಯಗಳು ನೃತ್ಯದ ಜೊತೆಯಲ್ಲಿವೆ. \"ತಿರುಣಿ ಕುಣಿತ\" ಎಂಬ ಐದು-ಹಂತದ ನೃತ್ಯದೊಂದಿಗೆ ಮೂರು, ಐದು ಅಥವಾ ಏಳು ಅನುಕ್ರಮಗಳಲ್ಲಿ ಹೆಜ್ಜೆಗಳನ್ನು ಪ್ರದರ್ಶಿಸುವ ನೃತ್ಯಗಾರರ ಸಂಖ್ಯೆಯು ಸಾಮಾನ್ಯವಾಗಿ ಸಮವಾಗಿರುತ್ತದೆ. ಗ್ರಾಮ ದೇವತೆ ಮತ್ತು ಹಾಸ್ಯದ ಹಾಡುಗಳನ್ನು ಒಳಗೊಂಡ ನೃತ್ಯವು ತಡರಾತ್ರಿಯವರೆಗೆ ಮುಂದುವರಿಯುತ್ತದೆ. ಹಬ್ಬದ ನಂತರ ಕಂಬ, ಕಳಸ, ವಾದ್ಯಗಳನ್ನು ಹೊಳೆಯಲ್ಲಿ ವಿಸರ್ಜಿಸಲಾಗುತ್ತದೆ.",
            image: rangaKunita,
        },
        {
            name: "ಸೋಬಾನೆ ಪದ",
            description: "ಸೋಬಾನೆ ಪದವು ಕರ್ನಾಟಕದಲ್ಲಿ,  ವಿಶೇಷವಾಗಿ ಗ್ರಾಮೀಣ ಪ್ರದೇಶಗಳಲ್ಲಿ ಜನಪ್ರಿಯವಾಗಿರುವ ಸಾಂಪ್ರದಾಯಿಕ ಜಾನಪದ ಕಲೆಯಾಗಿದೆ. ಇದು ಹೆರಿಗೆ ಮತ್ತು ಮದುವೆಯಂತಹ ಮಂಗಳಕರ ಸಂದರ್ಭಗಳಲ್ಲಿ ದೇವತೆಗಳನ್ನು ಸ್ತುತಿಸುವ ಭಕ್ತಿಗೀತೆಗಳನ್ನು ಹಾಡುವುದನ್ನು ಒಳಗೊಂಡಿರುತ್ತದೆ. ತಲೆಮಾರುಗಳ ಮೂಲಕ ರವಾನಿಸಲಾದ ಈ ಹಾಡುಗಳು ಸಾಂಸ್ಕೃತಿಕ ಮತ್ತು ಧಾರ್ಮಿಕ ವಿಷಯಗಳಲ್ಲಿ ಸಮೃದ್ಧವಾಗಿವೆ, ಸಮುದಾಯದ ಮೌಲ್ಯಗಳು ಮತ್ತು ನಂಬಿಕೆಗಳನ್ನು ಪ್ರತಿಬಿಂಬಿಸುತ್ತವೆ. ಸೋಬಾನೆ ಪದವನ್ನು ಸಾಮಾನ್ಯವಾಗಿ ಗುಂಪುಗಳಲ್ಲಿ ನಡೆಸಲಾಗುತ್ತದಾದ್ದರಿಂದ, ಇದು ಸಾಮರಸ್ಯ ಮತ್ತು ಆಧ್ಯಾತ್ಮಿಕವಾಗಿ ಉನ್ನತೀಕರಣದ ಅನುಭವವನ್ನು ಸೃಷ್ಟಿಸುತ್ತದೆ. ಈ ಜಾನಪದ ಕಲೆಯು ಪ್ರದೇಶದ ಸಾಂಸ್ಕೃತಿಕ ಪರಂಪರೆಯನ್ನು ಸಂರಕ್ಷಿಸುವುದಲ್ಲದೆ ಕೋಮು ಸಂಬಂಧಗಳನ್ನು ಬಲಪಡಿಸುತ್ತದೆ, ಸಂಗೀತ ಮತ್ತು ಭಕ್ತಿಯ ಮೂಲಕ ಜೀವನದ ಮಹತ್ವದ ಘಟನೆಗಳನ್ನು ಆಚರಿಸುತ್ತದೆ.",
            image: sobanePada,
        },
        {
            name: "ಪೂಜಾ ಕುಣಿತ",
            description: "ಪೂಜಾ ಕುಣಿತವು ಶಕ್ತಿ ದೇವತೆಗಳಿಗೆ ಸಂಬಂಧಿಸಿದ ಸಾಂಪ್ರದಾಯಿಕ ನೃತ್ಯವಾಗಿದ್ದು, ಬೆಂಗಳೂರು, ಮಂಡ್ಯ, ಕೋಲಾರ, ಚಿಕ್ಕಬಳ್ಳಾಪುರ, ತುಮಕೂರು ಮತ್ತು ಮೈಸೂರು ಜಿಲ್ಲೆಗಳಲ್ಲಿ ಪ್ರಚಲಿತವಾಗಿದೆ. ಹಬ್ಬಗಳ ಸಮಯದಲ್ಲಿ, ಗ್ರಾಮ ದೇವತೆಯನ್ನು ಸಾಂಕೇತಿಕವಾಗಿ 'ಪೂಜೆ'ಯ ಆಚರಣೆಯಲ್ಲಿ ತರಲಾಗುತ್ತದೆ. ನೃತ್ಯಗಾರರು ದೇವತೆಯ ಮುಖವಾಡಗಳನ್ನು ಧರಿಸುತ್ತಾರೆ ಮತ್ತು ಸುಮಾರು 5 ಅಡಿ ಉದ್ದ ಮತ್ತು 4 ಅಡಿ ಅಗಲದ ಬಿದಿರಿನ ರಚನೆಯೊಂದಿಗೆ ಪ್ರದರ್ಶನ ನೀಡುತ್ತಾರೆ. ರಚನೆಯನ್ನು ಹಿತ್ತಾಳೆ ಅಥವಾ ಬೆಳ್ಳಿಯ ಕಳಸಗಳು, ಆಭರಣಗಳು ಮತ್ತು ಹೂವುಗಳಿಂದ ಅಲಂಕರಿಸಲಾಗಿದೆ. ಭಕ್ತರು ಇದನ್ನು ಅಲಂಕರಿಸಿ ಚೈತನ್ಯಯುತವಾಗಿ ಕುಣಿದು ತಮ್ಮ ಭಕ್ತಿಯನ್ನು ಪ್ರದರ್ಶಿಸುತ್ತಾರೆ. ಇದರ ಕೆಳಭಾಗದಲ್ಲಿರುವ ಕಂಚಿನ ಅಥವಾ ಹಿತ್ತಾಳೆಯ ಮುಳ್ಳಿನ ಆಕಾರವು ತಲೆಯ ಮೇಲೆ ಹೊತ್ತುಕೊಂಡಾಗ ಅದನ್ನು ಸ್ಥಿರಗೊಳಿಸಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
            image: pooja_Kunitha,
        },
        {
            name: "ಗಂಜಿಫಾ ಕಲೆ",
            description: "ಗಂಜೀಫಾ ಸಾಂಪ್ರದಾಯಿಕ ಎಳೆಗಳ ಆಟವಾಗಿದ್ದು, ಇದು ರಾಜರು, ಆಸ್ಥಾನಿಕರು ಮತ್ತು ಸಾಮಾನ್ಯ ಜನಸಾಮಾನ್ಯರಲ್ಲಿ ಪಚಿಸಿ ಮತ್ತು ಚದುರಂಗದಂತೆಯೇ ವ್ಯಾಪಕ ಜನಪ್ರಿಯತೆಯನ್ನು ಗಳಿಸಿದೆ. ಭಾರತೀಯ ಗಂಜಿಫಾ ಎಳೆಗಳು ತಮ್ಮ ರೋಮಾಂಚಕ ಬಣ್ಣಗಳು ಮತ್ತು ಸಂಕೀರ್ಣವಾದ ವರ್ಣಚಿತ್ರಗಳಿಗೆ ಹೆಸರುವಾಸಿಯಾಗಿದೆ. 16 ನೇ ಶತಮಾನದ ಆರಂಭದಲ್ಲಿ ಮೊಘಲ್ ಚಕ್ರವರ್ತಿಗಳು ಭಾರತಕ್ಕೆ ಪರಿಚಯಿಸಿದ ಈ ಎಳೆಗಳು ವಿವಿಧ ರೂಪಗಳಲ್ಲಿ ದೇಶದಾದ್ಯಂತ ಹರಡಿತು. ಮೂಲ ಮೊಘಲ್ ಗಂಜಿಫಾ ಹಿಂದೂ ಆವೃತ್ತಿಯಾಗಿ ವಿಕಸನಗೊಂಡಿತು, ಇದನ್ನು ದಶಾವತಾರ ಗಂಜಿಫಾ ಎಂದು ಕರೆಯಲಾಗುತ್ತದೆ. ಈ ಕಲಾ ಪ್ರಕಾರವನ್ನು ವಿಶೇಷವಾಗಿ ಶ್ರೀರಂಗಪಟ್ಟಣದಲ್ಲಿ ಚೆನ್ನಾಗಿ ಅಭ್ಯಾಸ ಮಾಡಲಾಯಿತು, ಅದರ ಸೊಗಸಾದ ವಿನ್ಯಾಸ ಮತ್ತು ಐತಿಹಾಸಿಕ ಪ್ರಾಮುಖ್ಯತೆಯ ಮೂಲಕ ಭಾರತದ ಶ್ರೀಮಂತ ಸಾಂಸ್ಕೃತಿಕ ಪರಂಪರೆಯನ್ನು ಪ್ರದರ್ಶಿಸುತ್ತದೆ.",
            image: ganjifaCards,
        },
      ],
      cusine: [
        {
            name: "ರಾಗಿ ಮುದ್ದೆ ಮತ್ತು ಉಪ್ಪು ಸಾರು",
            description: "ರಾಗಿ ಮುದ್ದೆ ಮತ್ತು ಉಪ್ಪು ಸಾರು ಕರ್ನಾಟಕದ ಪಾಕಪದ್ಧತಿಯ ಸರ್ವೋತ್ಕೃಷ್ಟ ಖಾದ್ಯಗಳಾಗಿವೆ. ರಾಗಿ ಮುದ್ದೆ, ರಾಗಿ ಹಿಟ್ಟಿನಿಂದ ತಯಾರಿಸಿದ ದಟ್ಟವಾದ ಉಂಡೆಯನ್ನು ಸಾಮಾನ್ಯವಾಗಿ ಉಪ್ಪು ಸಾರು, ಮಸಾಲೆಗಳೊಂದಿಗೆ ಸುವಾಸನೆಯ ಕಟುವಾದ ಸಾರುಗಳೊಂದಿಗೆ ಆನಂದಿಸಲಾಗುತ್ತದೆ. ರಾಗಿ ಮುದ್ದೆಯನ್ನು ಉಪ್ಪು ಸಾರು ಜೊತೆ ಸವಿಯುವ ಮೊದಲು ಕರಗಿದ ಬೆಣ್ಣೆ ಅಥವಾ ತುಪ್ಪದಲ್ಲಿ ಅದ್ದಿದಾಗ ಅದರ ರುಚಿ ಮತ್ತು ವಿನ್ಯಾಸವನ್ನು ಹೆಚ್ಚಿಸುತ್ತದೆ. ಈ ಸಂಯೋಜನೆಯು ರುಚಿಕರ ಮತ್ತು ಪೌಷ್ಟಿಕಾಂಶದ ಊಟವನ್ನು ಒದಗಿಸುವುದಲ್ಲದೆ, ಕರ್ನಾಟಕದ ಆಹಾರ ಪರಂಪರೆಯಲ್ಲಿ ಸ್ಥಳೀಯ ಪದಾರ್ಥಗಳು ಮತ್ತು ಪಾಕಶಾಲೆಯ ಅಭ್ಯಾಸಗಳ ಸಾಂಸ್ಕೃತಿಕ ಮಹತ್ವವನ್ನು ಎತ್ತಿ ತೋರಿಸುತ್ತದೆ.",
            image: ragiMuddeandUppuSaru,
        },
        {
            name: "ಮದ್ದೂರು ವಡೆ",
            description: "ಮಂಡ್ಯ ಜಿಲ್ಲೆಯ ಮದ್ದೂರಿನ ಹುಟ್ಟಿಕೊಂಡ ಮದ್ದೂರು ವಡೆ ದಕ್ಷಿಣ ಭಾರತದ ಸುಪ್ರಪ್ರಸಿದ್ಧ ತಿಂಡಿಗಳಲ್ಲಿ ಒಂದಾಗಿದೆ. ಇದು ಅಕ್ಕಿ ಹಿಟ್ಟು, ರವೆ, ಮೈದಾ ಹಿಟ್ಟು, ಈರುಳ್ಳಿ, ಕರಿಬೇವಿನ ಎಲೆಗಳು ಮತ್ತು ಮಸಾಲೆಗಳನ್ನು ರುಬ್ಬಿಕೊಂಡು, ಎಣ್ಣೆಯಲ್ಲಿ ಕರಿದು ಮಾಡಿರುವ ಖಾರದ ಖಾದ್ಯವಾಗಿದೆ. ಹಿಟ್ಟನ್ನು ಸಮವಾದ ವೃತ್ತಾಕಾರದಲ್ಲಿ ರೂಪಿಸಿ, ಕಂಡು ಬಣ್ಣ ಬರುವವರೆಗೆ ಹುರಿಯಲಾಗುತ್ತದೆ, ಇದರ ಪರಿಣಾಮವಾಗಿ ಕುರುಕುಲಾದ ಹೊರಭಾಗ ಮತ್ತು ಮೃದುವಾದ, ಸುವಾಸನೆಯ ಒಳಭಾಗವು ಕಂಡುಬರುತ್ತದೆ. ಮದ್ದೂರು ವಡೆ ತನ್ನ ವಿಶಿಷ್ಟವಾದ ರುಚಿ ಮತ್ತು ಸುವಾಸನೆಗೆ ಹೆಸರುವಾಸಿಯಾಗಿದೆ, ಇದನ್ನು ಸಾಮಾನ್ಯವಾಗಿ ಚಹಾ-ಸಮಯದ ತಿಂಡಿಯಾಗಿ ಅಥವಾ ಹಬ್ಬದ ಊಟದ ಭಾಗವಾಗಿ ಆನಂದಿಸಲಾಗುತ್ತದೆ. ಇದರ ಜನಪ್ರಿಯತೆಯು ಕರ್ನಾಟಕದ ಆಚೆಗೂ ವ್ಯಾಪಿಸಿದ್ದು ಇದು ದಕ್ಷಿಣ ಭಾರತದಾದ್ಯಂತ ಅಚ್ಚುಮೆಚ್ಚಿನ ಸತ್ಕಾರವಾಗಿದೆ.",
            image: maddurVade,
        },
        {
            name: "ಚಿಬ್ಲು ಇಡ್ಲಿ",
            description: "ಚಿಬ್ಲು ಇಡ್ಲಿ ಕರ್ನಾಟಕದ ಮಳವಳ್ಳಿ ತಾಲೂಕಿನ ಹಲಗೂರಿನಿಂದ ಪ್ರಸಿದ್ಧವಾದ ಖಾದ್ಯವಾಗಿದೆ. ಇದು ಅದರ ವಿಶಿಷ್ಟವಾದ ತಯಾರಿಕೆಯ ವಿಧಾನಕ್ಕಾಗಿ ಹೆಸರುವಾಸಿಯಾಗಿದೆ. ಇಲ್ಲಿ ಇಡ್ಲಿ ಹಿಟ್ಟನ್ನು ಬಿದಿರಿನ ಬಟ್ಟಲುಗಳ ಒಳಗೆ ಆವಿಯಲ್ಲಿ ಬೇಯಿಸಲಾಗುತ್ತದಾದ್ದರಿಂದ ಇದಕ್ಕೆ ವಿಶಿಷ್ಟವಾದ ಸುವಾಸನೆ ಮತ್ತು ವಿನ್ಯಾಸವನ್ನು ನೀಡುತ್ತದೆ. ಈ ಇಡ್ಲಿಗಳು ತಮ್ಮ ಅದ್ಭುತ ರುಚಿ ಮತ್ತು ಆಹ್ಲಾದಕರ ಸುವಾಸನೆಗೆ ಹೆಸರುವಾಸಿಯಾಗಿದ್ದು ದೂರದ ಆಹಾರ ಉತ್ಸಾಹಿಗಳನ್ನು ಆಕರ್ಷಿಸುತ್ತದೆ. ಬಿದಿರು ಇಡ್ಲಿಗಳಿಗೆ ಸೂಕ್ಷ್ಮವಾದ ಮಣ್ಣಿನ ಕಂಪನ್ನು ನೀಡುತ್ತದೆ ಹಾಗು ಅವುಗಳ ಮೃದುವಾದ ವಿನ್ಯಾಸಕ್ಕೆ ಪೂರಕವಾಗಿದೆ. ಚಿಬ್ಲು ಇಡ್ಲಿಯು ಅದರ ಸಾಂಪ್ರದಾಯಿಕ ಬೇರುಗಳಿಗೆ ಮಾತ್ರವಲ್ಲದೆ ಸ್ಥಳೀಯ ಕರ್ನಾಟಕದ ಪಾಕಪದ್ಧತಿಯ ಸಾರವನ್ನು ಸೆರೆಹಿಡಿಯುವ ಅದರ ಸಂತೋಷಕರ ಪಾಕಶಾಲೆಯ ಅನುಭವಕ್ಕಾಗಿಯೂ ಸಹ ಸುಪ್ರಸಿದ್ಧವಾಗಿದೆ.",
            image: chibluIdli,
        },
        {
            name: "ಉಚ್ಚೆಲು ಚಟ್ನಿಯೊಂದಿಗೆ ರಾಗಿ ರೊಟ್ಟಿ",
            description: "ಉಚ್ಚೆಲು ಚಟ್ನಿಯೊಂದಿಗೆ ರಾಗಿ ರೊಟ್ಟಿ ರಾಗಿ ಧಾನ್ಯದಿಂದ ಮಾಡಿದ ಪೌಷ್ಟಿಕ ಭಕ್ಷ್ಯವಾಗಿದ್ದು ಇದು ಮಂಡ್ಯ ಜಿಲ್ಲೆಯಲ್ಲಿ ಜನಪ್ರಿಯವಾಗಿದೆ. ರಾಗಿ ರೊಟ್ಟಿ ರಾಗಿ ಹಿಟ್ಟಿನಿಂದ ತಯಾರಿಸಿದ ಚಪ್ಪಟೆ ರೊಟ್ಟಿಯಾಗಿದ್ದು, ಈರುಳ್ಳಿ, ಹಸಿರು ಮೆಣಸಿನಕಾಯಿಗಳು ಮತ್ತು ಮಸಾಲೆಗಳೊಂದಿಗೆ ಬೆರೆಸಿ, ನಂತರ ಗರಿಗರಿಯಾಗುವವರೆಗೆ ತವಾದ ಮೇಲೆ ಬೇಯಿಸಲಾಗುತ್ತದೆ. ಹುರಿದ ಎಳ್ಳು, ತೆಂಗಿನಕಾಯಿ ಮತ್ತು ಮಸಾಲೆಗಳಿಂದ ಮಾಡಿದ ಉಚ್ಚೆಲು ಚಟ್ನಿ, ರಾಗಿ ರೊಟ್ಟಿಗೆ ಅದರ ಕಾಯಿ ಮತ್ತು ಮಸಾಲೆಯುಕ್ತ ಪರಿಮಳವನ್ನು ಪೂರಕವಾಗಿರುತ್ತದೆ. ರಾಗಿಯು ಫೈಬರ್ ಮತ್ತು ಪೋಷಕಾಂಶಗಳಲ್ಲಿ ಸಮೃದ್ಧವಾಗಿರುವುದರಿಂದ ಈ ಖಾದ್ಯವು ಅದರ ರುಚಿಗೆ ಮಾತ್ರವಲ್ಲದೆ ಅದರ ಆರೋಗ್ಯ ಪ್ರಯೋಜನಗಳಿಗಾಗಿಯೂ ಮೌಲ್ಯಯುತವಾಗಿದೆ.",
            image: ragiRottiwithUchchelluChutney,
        },
        {
            name: "ಮೇಲುಕೋಟೆ ಪುಳಿಯೋಗರೆ ಮತ್ತು ಪೊಂಗಲ್",
            description: "ಮೇಲುಕೋಟೆಯು ಪುಳಿಯೋಗರೆ ಮತ್ತು ಪೊಂಗಲ್‌ನಂತಹ ಸಾಂಪ್ರದಾಯಿಕ ಭಕ್ಷ್ಯಗಳಿಗೆ ಸಹ ಹೆಸರುವಾಸಿಯಾಗಿದೆ. ಪುಳಿಯೋಗರೆ, ಕಟುವಾದ ಮತ್ತು ಮಸಾಲೆಯುಕ್ತ ಹುಣಸೆಹಣ್ಣಿನ ಸುವಾಸನೆಯ ಅನ್ನದ ಖಾದ್ಯವಾಗಿದ್ದು ಅದರ ಸುವಾಸನೆ ಮತ್ತು ಪರಿಮಳಯುಕ್ತ ಮಸಾಲೆಗಳಿಗಾಗಿ ಸ್ಥಳೀಯರು ಮತ್ತು ಸಂದರ್ಶಕರಲ್ಲಿ ಅಚ್ಚುಮೆಚ್ಚಿನದ್ದಾಗಿದೆ. ಅದಲ್ಲದೆ ಪೊಂಗಲ್ ಅನ್ನ ಮತ್ತು ಮಸೂರದಿಂದ ತಯಾರಿಸಿದ ಸಾಂತ್ವನ ಭಕ್ಷ್ಯವಾಗಿದೆ, ಸಾಮಾನ್ಯವಾಗಿ ಕರಿಮೆಣಸು, ಜೀರಿಗೆ ಮತ್ತು ತುಪ್ಪದೊಂದಿಗೆ ಮಸಾಲೆ ಹಾಕಿ ಮಾಡಲಾಗುವ ಇದು ಕೆನೆಯುಕ್ತ ಮತ್ತು ಆರೋಗ್ಯಕರ ರುಚಿಯನ್ನು ನೀಡುತ್ತದೆ. ಎರಡೂ ಭಕ್ಷ್ಯಗಳು ಮೇಲುಕೋಟೆಯ ಸಾಂಸ್ಕೃತಿಕ ಮತ್ತು ಪಾಕಶಾಲೆಯ ಪರಂಪರೆಗೆ ಅವಿಭಾಜ್ಯವಾಗಿವೆ, ಇದು ಪ್ರದೇಶದ ಶ್ರೀಮಂತ ಸಂಪ್ರದಾಯಗಳು ಮತ್ತು ದಕ್ಷಿಣ ಭಾರತೀಯ ಪಾಕಪದ್ಧತಿಯ ಕಲಾತ್ಮಕತೆಯನ್ನು ಪ್ರತಿಬಿಂಬಿಸುತ್ತದೆ.",
            image: melukotePuliyogareandPongal,
        },
        {
            name: "ಮಂಡ್ಯ ಬಾಡೂಟ ",
            description: "ಮಂಡ್ಯ ಬಾಡೂಟವು ಮಂಡ್ಯ ಜಿಲ್ಲೆಯಲ್ಲಿ ಸವಿಯುವ ಸ್ಥಳೀಯ ಮಾಂಸಾಹಾರಿ ವಿಶೇಷತೆಗಳನ್ನು ಎತ್ತಿ ತೋರಿಸುತ್ತದೆ. ಬನ್ನೂರು ಕುರಿ ಬಿರಿಯಾನಿ, ಸುವಾಸನೆಯ ಬಾಸುಮತಿ ಅಕ್ಕಿ ಮತ್ತು ಸುಗಂಧಭರಿತ ಮಸಾಲೆಗಳೊಂದಿಗೆ ಬೇಯಿಸಿದ ಕೋಮಲ ಮಟನ್‌ಗೆ ಹೆಸರುವಾಸಿಯಾಗಿರುವ ಇದು ಅದರ ಶ್ರೀಮಂತ ಸುವಾಸನೆ ಮತ್ತು ವಿನ್ಯಾಸಗಳಿಂದ ಆಕರ್ಷಿಸುತ್ತದೆ. ನಾಟಿ ಕೋಳಿ ಸಾರನ್ನು ಸಾಮಾನ್ಯವಾಗಿ ರಾಗಿ ಮುದ್ದೆಯೊಂದಿಗೆ ಸವಿಯಲಾಗುತ್ತದೆ.",
            image: mandyaBaduta,
        },
      ]
    }
}

export default data;