import React, { useState, useEffect } from 'react';
import { Box } from "@mui/material";
import ArtCard from './ArtCard';
import ArtModal from './ArtModal';
import data from './data';
import { makeStyles } from "@mui/styles";
import banner from '../../Assets/Banners/ArtandCuisine.jpg'
import bannerKannada from '../../Assets/Banners/ArtandCuisineKannada.jpg'

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    fontFamily: 'Montserrat, Arial, sans-serif',
  },
  sectionContent: {
    fontFamily: 'Montserrat, Arial, sans-serif',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '28px',
    textAlign: 'left',
  },
  aboutUsImageContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  aboutUsImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  underlinedHeading: {
    fontFamily: 'Montserrat, sans-serif',
    marginBottom: '10px',
    fontSize: '28px',
    color: 'black',
    position: 'relative',
    textDecoration: 'none',
    display: 'inline-block',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: 2,
      width: '60%',
      borderBottom: '4px solid #FFD700 !important',
    },
  },
  // Responsive adjustments
  artsAndCuisineContainer: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: 0,
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: '100px',
      marginRight: '60px',
    },
  },
  artCardsContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px', // Adjust as needed
    flexWrap: 'wrap', // Ensures cards wrap when needed
  },
  artCard: {
    width: 'calc(50% - 10px)', // Two cards per row on tablets and larger
    marginBottom: '20px', // Space between cards
    [theme.breakpoints.down('sm')]: {
      width: '100%', // One card per row on mobile
      margin: '0', // Remove margin on mobile
    },
  },
}));

const ArtsAndCuisine = ({ isKannada }) => {
  const artsData = isKannada ? data.kn.arts : data.en.arts;
  const cuisineData = isKannada ? data.kn.cusine : data.en.cusine;

  const [selectedArt, setSelectedArt] = useState(null);

  const classes = useStyles();

  const handleCardClick = (art) => {
    setSelectedArt(art);
  };

  const handleCloseModal = () => {
    setSelectedArt(null);
  };

  const artsAndCuisine = isKannada ? 'ಕಲೆ ಮತ್ತು ಪಾಕಪದ್ಧತಿ' : 'Art and Cuisine';

  return (
    <>
      <Box className={classes.aboutUsImageContainer}>
        <img className={classes.aboutUsImage} src={isKannada? bannerKannada : banner} alt="aboutUs" />
      </Box>
      
      <div className={`arts-and-cuisine ${classes.artsAndCuisineContainer}`}>
        <div className="arts-section">
          <h2 className={classes.underlinedHeading}>
            {isKannada ? 'ಕಲೆಗಳು' : 'Arts'}
          </h2>
          <div className={classes.artCardsContainer}>
            {artsData.map((art, index) => (
              <ArtCard key={index} art={art} isKannada={isKannada} onClick={handleCardClick} className={classes.artCard} />
            ))}
          </div>
        </div>
        <div className="arts-and-cuisine">
          <h2 className={classes.underlinedHeading}>
            {isKannada ? 'ಪಾಕಪದ್ಧತಿ' : 'Cuisine'}
          </h2>
          <div className={classes.artCardsContainer}>
            {cuisineData.map((cuisine, index) => (
              <ArtCard key={index} art={cuisine} isKannada={isKannada} onClick={handleCardClick} className={classes.artCard} />
            ))}
          </div>
        </div>
      </div>

      {selectedArt && <ArtModal art={selectedArt} isKannada={isKannada} onClose={handleCloseModal} />}
    </>
  );
};

export default ArtsAndCuisine;
