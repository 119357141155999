import React, { useState, useEffect } from "react";
import { Box, Button, Typography, Grid, useTheme, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MapComponent from '../../components/MapContent';
import '../css/home.css';
import home_10 from '../../Assets/homeImages/home/home_9.jpg';
import { placesData } from "./destinationData";
import { Component as TravelCard } from './Component'; // Adjust the import path as necessary
import banner from '../../Assets/Banners/Destinations.jpg'
import bannerKannada from '../../Assets/Banners/DestinationsKannada.jpg'
import { isDOMComponent } from "react-dom/test-utils";

const useStyles = makeStyles((theme) => ({
  imageSlider: {
    width: '100%',
    height: '90vh',
    opacity: '0px',
  },
  destinationBlocks: {
    marginLeft: '50px',
    marginRight: '50px',
  },
  category: {
    maxWidth: '100%',
    textAlign: 'left',
    marginBottom: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  smallHeading: {
    fontFamily: 'Urbanist',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '25.95px',
    textAlign: 'left',
  },
  popularDestHeading: {
    fontFamily: 'Urbanist',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '25.95px',
    textAlign: 'left',
  },
  popularDestBlock: {
    background: '#2B3030',
    color: '#E0F2EB',
    width: '100%',
    padding: '10px',
  },
  placeText: {
    fontFamily: 'Urbanist',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    textAlign: 'center',
  },
  exploreButton: {
    backgroundColor: '#f1981a',
    color: '#000',
    fontFamily: 'Montserrat, sans-serif',
    textDecoration: 'none',
    borderRadius: '5px',
    transition: 'background 0.3s',
    border: 'none',
    cursor: 'pointer',
    padding: '10px 20px',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '10px',
    marginTop: '-50px',
    position: 'relative', /* Added to contain the image */
  },
  cardImage: {
    width: 'auto', /* Set width to auto to maintain aspect ratio */
    height: '100%', /* Ensure image covers the entire height of its container */
    maxWidth: '100%', /* Ensure image doesn't exceed the container's width */
    objectFit: 'cover', /* Maintain aspect ratio */
  },
  sectionTitle: {
    fontFamily: 'Montserrat, Arial, sans-serif',
  },
  sectionContent: {
   fontFamily: 'Montserrat, Arial, sans-serif',
    // [theme.breakpoints.down('sm')]: {
    //   textAlign: 'center',
    // },
    //fontFamily: 'Urbanist',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '28px',
    textAlign: 'left',

  },
  aboutUsImageContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  aboutUsImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  gridContainer: {
    flexGrow: 1,
    padding: '20px',
  },
  sectionText: {
    maxWidth: '80%',
  },
  buttonContainer: {
    textAlign: 'right',
    flex: '1',
  },
  underlinedHeading: {
    fontFamily: 'Montserrat, sans-serif',
    marginBottom: '10px',
    fontSize : '28px',
    color: 'black', // Set font color to black
    position: 'relative', // Required for positioning pseudo-element
    textDecoration: 'none', // Remove default underline
    display: 'inline-block', // Ensure the pseudo-element respects the text width
    '&::after': {
      content: '""', // Required content for pseudo-element
      position: 'absolute',
      left: 0,
      bottom: -2,
      width: '60%', // Set width of the underline to 100% of the text width
      borderBottom: '4px solid #FFD700 !important', // Define double underline
    },
  },
}));

const API_BASE_URL = 'http://ec2-3-105-71-196.ap-southeast-2.compute.amazonaws.com:8080/api/Place/circuit';

const Destination = ({ isKannada }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [spiritualCards, setSpiritualCards] = useState([]);
  const [naturalCards, setNaturalCards] = useState([]);
  const [historicalCards, setHistoricalCards] = useState([]);
  const [otherCards, setOtherCards] = useState([]);

  useEffect(() => {
    const fetchCircuitData = async (circuit, setCards) => {
      try {
        const response = await axios.get(`${API_BASE_URL}/${circuit}`);
        const cards = response.data.map(place => ({
          image: place.images && place.images[0] ? place.images[0] : null,
          id: place.id,
          title: place.name,
          titleKannada: place.nameKannada,
          description: place.description,
          descriptionKannada: place.descriptionKannada,
        })).slice(0, 3); // Only take the first 3 items
        setCards(cards);
      } catch (error) {
        const filteredPlaces = placesData.filter(place => place.circuit === circuit);
        const cards = filteredPlaces.map(place => ({
          image: place.images && place.images[0] ? place.images[0] : null,
          id: place.id,
          title: place.name,
          titleKannada: place.nameKannada,
          description: place.description,
          descriptionKannada: place.descriptionKannada,
        })).slice(0, 3); // Only take the first 3 items
        setCards(cards);
        console.error(`Error fetching ${circuit} data:`, error);
      }
    };

    fetchCircuitData('spiritual', setSpiritualCards);
    fetchCircuitData('natural', setNaturalCards);
    fetchCircuitData('historical', setHistoricalCards);
    fetchCircuitData('other', setOtherCards);
  }, []);

  const getReadyToUnwind = isKannada ? 'ವಿಶ್ರಾಂತಿ ಪಡೆಯಲು ಸಿದ್ಧರಾಗಿ' : 'Get ready to unwind';
  const exploreNowText = isKannada ? 'ಇದನ್ನು ಅನ್ವೇಷಿಸಿ' : 'Explore Now';

  const naturalAttractionsHeader = isKannada ? 'ಪ್ರಕೃತಿ ಮತ್ತು ವನ್ಯಜೀವಿ' : 'Nature and WildLife';
  const spiritualAttractionsHeader = isKannada ? 'ಆಧ್ಯಾತ್ಮಿಕ ತಾಣಗಳು' : 'Spiritual Destinations';
  const historicalAttractionsHeader = isKannada ? 'ಐತಿಹಾಸಿಕ ತಾಣಗಳು' : 'Historical Destinations';
  const otherAttractionsHeader = isKannada ? 'ಇತರೆ ತಾಣಗಳು' : 'Other Destinations';
  const destinations = isKannada ? 'ತಾಣಗಳು' : 'Destinations'

  const naturalAttractionsParagraph = isKannada ? 'ಮಂಡ್ಯದ ಅಭಯಾರಣ್ಯಗಳ ನೈಸರ್ಗಿಕ ಸೌಂದರ್ಯ ಹಾಗು ವೈವಿಧ್ಯಮಯ ವನ್ಯಜೀವಿಗಳು ಮತ್ತು ರಮಣೀಯ ಭೂದೃಶ್ಯಗಳಲ್ಲಿ ಮೈಮರೆಯಿರಿ.' : 'Immerse yourself in the natural beauty and diverse wildlife of Mandya\'s scenic landscapes and sanctuaries.';
  const spiritualAttractionsParagraph = isKannada ? 'ಮಂಡ್ಯದ ರೋಮಾಂಚಕಾರಿ ಚಾರಣಗಳು, ಜಲ ಕ್ರೀಡೆಗಳು ಮತ್ತು ಹೊರಾಂಗಣ ಚಟುವಟಿಕೆಗಳೊಂದಿಗೆ ರೋಮಾಂಚಕ ಸಾಹಸಗಳನ್ನು ಪ್ರಾರಂಭಿಸಿ.' : 'Embark on thrilling adventures with Mandya\'s exciting treks, water sports, and outdoor activities.';
  const historicalAttractionsParagraph = isKannada ? 'ಮಂಡ್ಯದ ಐತಿಹಾಸಿಕ ಹೆಗ್ಗುರುತುಗಳ ಶ್ರೀಮಂತ ಪರಂಪರೆ ಮತ್ತು ವಾಸ್ತುಶಿಲ್ಪದ ಅದ್ಭುತಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಸಿದ್ಧರಾಗಿ.' : 'Step back in time as you explore the rich heritage and architectural marvels of Mandya\'s historical landmarks.';
  const otherAttractionsParagraph = isKannada ? 'ಮಂಡ್ಯದ ವೈವಿಧ್ಯಮಯ ಸೌಂದರ್ಯವನ್ನು ಪ್ರದರ್ಶಿಸುವ ಅನನ್ಯ ತಾಣಗಳ ವೈವಿಧ್ಯಮಯ ಸ್ಥಳಗಳನ್ನು ಅನ್ವೇಷಿಸಿ.' : 'Discover a diverse array of unique spots and hidden gems that showcase the varied charm of Mandya.';


  const images = [
    home_10,
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  const exploreDest = isKannada ? 'ಆಕರ್ಷಣೆಗಳನ್ನು ಅನ್ವೇಷಿಸಿ' : 'Explore Destinations';

  const renderCard = (id, image, title, titleKannada, description, descriptionKannada) => (
    <TravelCard 
      explore={isKannada ? 'ಅನ್ವೇಷಿಸಿ' : 'Explore'}
      image={image || "image.png"}
      text={isKannada ? titleKannada : title}
      text1={isKannada ? descriptionKannada: description}
      onClick={() => navigate(`/destination/detail?search=${id}`)} // Add onClick handler to navigate to detail page
    />
  );

  const renderSection = (header, paragraph, circuit, cards) => (
    <Box className={classes.destinationBlocks} style= {{marginLeft : isMobile ? '10px': '50px', marginRight : isMobile ? '10px' : '50px'}}>
      <Box className={classes.category}>
        <div className={classes.sectionText}>
          <h2 className={classes.underlinedHeading}>{header}</h2>
          <p>{paragraph}</p>
        </div>
        <div className={classes.buttonContainer}>
          {
            isMobile ? <></>:<Button className={classes.exploreButton} onClick={() => navigate(`category?search=${circuit}`)}>
              {exploreNowText}
            </Button>
          }
        </div>
      </Box>
        { 
            isMobile ? <Button className={classes.exploreButton} style ={{marginTop: isMobile ? '-50px': ''}} onClick={() => navigate(`category?search=${circuit}`)}>
              {exploreNowText}
            </Button> : <></>
          }
      <Grid container spacing={3} className={classes.gridContainer}>
        {cards.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            {renderCard(card.id, card.image, card.title, card.titleKannada, card.description, card.descriptionKannada)}
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  return (
    <div>
      <Box className={classes.aboutUsImageContainer}>
        <img className={classes.aboutUsImage} src={isKannada? bannerKannada : banner} alt="aboutUs" />
      </Box>
      
      {renderSection(spiritualAttractionsHeader, spiritualAttractionsParagraph, 'spiritual', spiritualCards)}
      {renderSection(naturalAttractionsHeader, naturalAttractionsParagraph, 'natural', naturalCards)}
      {renderSection(historicalAttractionsHeader, historicalAttractionsParagraph, 'historical', historicalCards)}
      {renderSection(otherAttractionsHeader, otherAttractionsParagraph, 'other', otherCards)}

      <Box style={{ marginLeft: isMobile ? '10px' : '50px', marginRight: isMobile ? '10px' : '50px' }}>
        <div className={classes.sectionTexts}>
          <Typography variant="h5"><b>{getReadyToUnwind}</b></Typography>
          <MapComponent isKannada={isKannada} />
        </div>
      </Box>
    </div>
  );
}

export default Destination;

