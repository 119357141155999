// src/pages/Home/Home.js
import React, { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, Typography, Grid, CardContent, CardMedia, useMediaQuery,
  useTheme } from "@mui/material";
import '../css/home.css';
import home_1 from '../../Assets/homeImages/home/home_1.jpg';
import home_2 from '../../Assets/homeImages/home/home_2.jpg';
import home_3 from '../../Assets/homeImages/home/home_3.jpg';
import home_4 from '../../Assets/homeImages/home/home_4.jpg';
import home_5 from '../../Assets/homeImages/home/home_5.jpg';
import home_6 from '../../Assets/homeImages/home/home_6.jpg';
import home_7 from '../../Assets/homeImages/home/home_7.jpg';
import home_8 from '../../Assets/homeImages/home/home_8.jpg';
import home_9 from '../../Assets/homeImages/home/home_9.jpg';
import home_10 from '../../Assets/homeImages/home/home_10.jpg';
import popular_1 from '../../Assets/Popular/Melukote.jpg';
import popular_2 from '../../Assets/Popular/KashiV.jpg';
import popular_3 from '../../Assets/Popular/GaganaChukki.jpg';
import popular4 from '../../Assets/Popular/bheem.JPG';
import natural_destination from '../../Assets/Destination/natural_destination.jpg';
import temple from '../../Assets/Destination/spiritual.jpg';
import historical from '../../Assets/Destination/historical.jpg';
import other from '../../Assets/Destination/other.jpg';
import { makeStyles } from "@mui/styles";

import {
  eventsData
} from './upcomingFestivals';
import MapComponent from '../../components/MapContent';

const festivalData = eventsData.splice(0,4);

const useStyles = makeStyles((theme) => ({
  heading: {
    paddingTop: '40px',
    fontFamily: 'Montserrat, sans-serif',
  },
  imageGrid: {
    gap: '100px',
  },
  imageItem: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '10px',
    transition: 'transform 0.5s ease', 
    '& img': {
      width: '100%',
      height: '100%',
      borderRadius: '10px',
      objectFit: 'cover',
      filter: 'brightness(0.7)',
      transition: 'transform 0.5s ease',    
    },

    '&:hover img': {
      transform: 'scale(1.01)',
    },

    '& .overlay': {
      borderRadius: '10px',
      marginLeft: '18px',
      position: 'absolute',
      bottom: '5',
      background: 'linear-gradient(to top, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)), linear-gradient(to right, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0))',
      color: '#fff',
      width: '86%',
      height: 'justify-content',
      textAlign: 'start',
      fontSize: '20px',
      fontWeight: '600',
      paddingLeft: '25px',

      fontFamily: 'Montserrat, sans-serif',
    },
  },
  largeImage: {
    // height: '800px',
  },
  smallImage: {
    height: '200px',
  },
  
  popularDestination: {
    margin: '0 auto',
    maxWidth: '100px',
    padding: '40px 20px',
    textAlign: 'center',
    fontFamily: 'Montserrat, sans-serif',
  },
  loreumIpsumShortParagraph: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '28px',
    textAlign: 'center',
    marginBottom: '40px',
  },
  popularDestBlock: {
    background: '#2B3030',
    color: '#E0F2EB',
    padding: '20px',
    fontFamily: 'Montserrat, sans-serif',
    textAlign: 'left',
    opacity: 0.8,
    transition: 'opacity 0.3s ease',
    '&:hover': {
      opacity: 1,
    },
  },
  placeText: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '24px',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: '100%',
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  cardMedia: {
    height: '250px', // Fixed height to maintain consistency%
    width: '100%',
    objectFit: 'cover',
  },
  cardContent: {
    backgroundColor: '#2B3030',
    color: '#E0F2EB',
    height: '140px',
  },
  getReadyToUnWind: {
    marginLeft: '60px',
    marginRight: '60px',
    marginTop: '50px',
    fontFamily: 'Montserrat, sans-serif',
  },
  getReadyToUnWindBlock: {
    display: 'flex',
    flexDirection: 'row',
    rowGap: '130px',
  },
  upcomingEventsBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#F2F2F2',
    padding: '50px 20px',
  },
  eventsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '20px',
  },
  eventCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    maxWidth: '600px',
    backgroundColor: '#fff',
    boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
    borderRadius: '10px',
    overflow: 'hidden',
    transition: 'transform 0.3s ease',  // Adding transition for smooth hover effect
    '&:hover': {
        transform: 'scale(1.03)',  // Scale up slightly on hover
        boxShadow: '0px 0px 20px rgba(0,0,0,0.2)',  // Adjusting box-shadow on hover
    },
  },
  eventImage: {
    width: '150px',
    height: '150px',
    objectFit: 'cover',
  },
  eventDetails: {
    padding: '20px',
    width: '100%',
  },
  eventDate: {
    fontSize: '12px',
    marginBottom: '5px',
    color: '#666',
  },
  eventTitle: {
    fontSize: '18px',
    marginBottom: '10px',
    fontWeight: 'bold',
    color: '#333',
  },
  eventDescription: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '@media (max-width: 768px)': {
    eventCard: {
      flexDirection: 'column',
      textAlign: 'center',
    },
    eventImage: {
      width: '100%',
      height: '100px',
      objectFit: 'cover',
    },
    eventDetails: {
      padding: '10px',
    },
  },
  homeBlock1: {
    marginLeft: '60px',
    marginRight: '60px',
    maxWidth: '100%'
  },

  paragraph: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '25.95px',
    textAlign: 'left'
  },
  
  underlinedHeading: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize : '28px',
    color: 'black', // Set font color to black
    position: 'relative', // Required for positioning pseudo-element
    textDecoration: 'none', // Remove default underline
    display: 'inline-block', // Ensure the pseudo-element respects the text width
    '&::after': {
      content: '""', // Required content for pseudo-element
      position: 'absolute',
      left: 0,
      opacity:'0.7',
      bottom: -2,
      width: '60%', // Set width of the underline to 100% of the text width
      borderBottom: '4px solid #FFD700 !important', // Define double underline
    },
  },
}))
;

const images = [
  home_1,
  home_2,
  home_3,
  home_4,
  home_5,
  home_6,
  home_7,
  home_8, 
  home_9,
  home_10,
];


const Home = ({ isKannada }) => {
  const classes = useStyles();
  const navigate = useNavigate ();
  const [currentSlide, setCurrentSlide] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const mapSrc = isKannada
    ? "https://www.google.com/maps/embed/v1/place?key=AIzaSyD6Kc3F30Wi9Egb-DCzs3ToaksuFGEJoxs&q=ಮಂಡ್ಯ ಜಿಲ್ಲೆಯ ಪ್ರವಾಸೋದ್ಯಮ ಇಲಾಖೆ"
    : "https://www.google.com/maps/embed/v1/place?key=AIzaSyD6Kc3F30Wi9Egb-DCzs3ToaksuFGEJoxs&q=Tourism Department Mandya Dist";


 const headerMandyaParagraph = isKannada ? 'ಪುರಾತನ ಇತಿಹಾಸ ಮತ್ತು ರೋಮಾಂಚಕ ಸಂಸ್ಕೃತಿಯು ಶಾಂತಿಯ ಧಾಮವನ್ನು ಸೃಷ್ಟಿಸಲು ಒಮ್ಮುಖವಾಗುವ ಕಾಲಾತೀತ ಸ್ಥಳ ಇದಾಗಿದೆ. ಮಂಡ್ಯದ ಶ್ರೀಮಂತ ಪರಂಪರೆ ಮತ್ತು ರಮಣೀಯ ಅದ್ಭುತಗಳ ಮೂಲಕ ಅವಿಸ್ಮರಣೀಯ ಪ್ರಯಾಣವನ್ನು ಪ್ರಾರಂಭಿಸಿ, ಹೆಜ್ಜೆಯಲ್ಲಿ ಶಾಂತಿ ಮತ್ತು ಸ್ಫೂರ್ತಿಯನ್ನು ಕಂಡುಕೊಳ್ಳಿ.'
   : 'A timeless place where ancient history and vibrant culture converge to create a haven of peace. Embark on an unforgettable journey through Mandya\'s rich heritage and scenic wonders, finding tranquility and inspiration at every turn.';
  const startExploring = isKannada ? 'ಅನ್ವೇಷಿಸಿ' : 'Start Exploring';

  const exploreMandyaHeading = isKannada ? 'ಮಂಡ್ಯವನ್ನು ಅನ್ವೇಷಿಸಿ' : 'Explore Mandya';
  const exploreMandyaParagraph = isKannada ? 'ಭವ್ಯವಾದ ದೇವಾಲಯದಿಂದ ಆಧ್ಯಾತ್ಮಿಕ ಮತ್ತು ಐತಿಹಾಸಿಕ ಹೆಗ್ಗುರುತುಗಳು ಮತ್ತು ಅದ್ಭುತ ಭೂದೃಶ್ಯಗಳವರೆಗೆ- ಮಂಡ್ಯ ಜಿಲ್ಲೆಯು ಕಾಲಾತೀತ ಸೌಂದರ್ಯ ಮತ್ತು ಸಾಂಸ್ಕೃತಿಕ ಶ್ರೀಮಂತಿಕೆಯ ನಿಧಿ. ಪ್ರತಿಯೊಂದು ಆಕರ್ಷಣೆಯು ಜಿಲ್ಲೆಯ ಪರಂಪರೆ ಮತ್ತು ನೈಸರ್ಗಿಕ ವೈಭವದಲ್ಲಿ ತಲ್ಲೀನವಾಗಲು ನಿಮ್ಮನ್ನು ಆಹ್ವಾನಿಸುತ್ತದೆ. ಶ್ರೀಮಂತ ಇತಿಹಾಸ, ಆಧ್ಯಾತ್ಮಿಕತೆ ಮತ್ತು ರಮಣೀಯ ಸೌಂದರ್ಯದ ವಿವರವಾದ ನೋಟವನ್ನು ನೀಡುವ ಮಂಡ್ಯದ ವೈವಿಧ್ಯಮಯ ಪ್ರವಾಸಿ ಆಕರ್ಷಣೆಗಳ ಮೂಲಕ ನಿಮ್ಮ ಪ್ರಯಾಣವನ್ನು ಪ್ರಾರಂಭಿಸಿ.' : 
  'Explore Mandya, a treasure trove of timeless beauty and cultural richness, from the majestic temple to the spiritual and historical landmarks and the breathtaking landscapes. Each attraction invites you to immerse yourself in the district\'s heritage and natural splendor. Embark on a journey through Mandya’s diverse tourist attractions, each offering a detailed glimpse into the rich history, spirituality, and scenic beauty that define this captivating destination.';

  const naturalAttrBtn = isKannada ? 'ನೈಸರ್ಗಿಕ ಆಕರ್ಷಣೆಗಳು' : 'Natural Attractions';
  const spiritualAttrBtn = isKannada ? 'ಆಧ್ಯಾತ್ಮಿಕ ಆಕರ್ಷಣೆಗಳು' : 'Spiritual Attractions';
  const historicalAttrBtn = isKannada ? 'ಐತಿಹಾಸಿಕ ಆಕರ್ಷಣೆಗಳು' : 'Historical Attractions';
  const otherAttrBtn = isKannada ? 'ಇತರೆ ಆಕರ್ಷಣೆಗಳು' : 'Other Attractions';

  const melukoteHeader = isKannada ? 'ಮೇಲುಕೋಟೆ' : 'Melukote';
  const bheemeshwariHeader = isKannada ? 'ಭೀಮೇಶ್ವರಿ ಪ್ರಕೃತಿ ಶಿಬಿರ' : 'Bheemeshwari Adventure Camp';
  const kashiHeader = isKannada ? 'ಕಾಶಿ ವಿಶ್ವನಾಥ ದೇವಸ್ಥಾನ' : 'Kashi Vishwanath Temple';
  const gaganachukkiHeader = isKannada ? 'ಗಗನಚುಕ್ಕಿ ಜಲಪಾತ' : 'Gaganachukki Falls';

  const getReadyToUnwind = isKannada ? 'ವಿಶ್ರಾಂತಿ ಪಡೆಯಲು ಸಿದ್ಧರಾಗಿ' : 'Get Ready to Unwind';
  const popularDestinationHeader = isKannada ? 'ಜನಪ್ರಿಯ ಆಕರ್ಷಣೆಗಳು' : ' Popular Destinations';
  const popularDestParagraph = isKannada ? 'ದೂರದೂರುಗಳಿಂದ ಪ್ರಯಾಣಿಕರನ್ನು ಆಕರ್ಷಿಸುವ ಮಂಡ್ಯದ ಅತ್ಯಂತ ಪ್ರಖ್ಯಾತ ಆಕರ್ಷಣೆಗಳನ್ನು ಅನ್ವೇಷಿಸಿ. ಮಂಡ್ಯದಲ್ಲಿ ಭೇಟಿ ನೀಡಲೇಬೇಕಾದ ಸ್ಥಳಗಳ ವಿವರವಾದ ಒಳನೋಟಗಳಿಗಾಗಿ ಇಲ್ಲಿ ನೋಡಿ, ಈ ಸ್ಥಳಗಳನ್ನು ನಿಜವಾಗಿಯೂ ಅವಿಸ್ಮರಣೀಯವಾಗಿಸುವ ರಮಣೀಯ ಭೂದೃಶ್ಯಗಳು ಮತ್ತು ಶ್ರೀಮಂತ ಪರಂಪರೆಯ ಮೂಲಕ ನಿಮಗೆ ಮಾರ್ಗದರ್ಶನ ನೀಡುತ್ತದೆ.' : 
    'Uncover Mandya\'s most sought-after attractions that captivate travelers from near and far. Dive in for detailed insights into Mandya’s must-visit destinations, guiding you through the enchanting landscapes and rich heritage that make these places truly unforgettable.';
  const upcomingFestivals = isKannada ? 'ಮುಂಬರುವ ಹಬ್ಬಗಳು' : 'Upcoming Festivals';
  const upComingFestivalsParagraph = isKannada ? 'ಮಂಡ್ಯ ಜಿಲ್ಲೆಯ ರೋಮಾಂಚಕ ಆಚರಣೆಗಳ ವೈಭವವನ್ನು ಅನುಭವಿಸಿ. ಪ್ರವಾಸಿಗರಿಗೆ ಸಾಂಪ್ರದಾಯಿಕ ಪದ್ಧತಿಗಳು ಮತ್ತು ಕೋಮು ಸೌಹಾರ್ದತೆಯ ತಲ್ಲೀನಗೊಳಿಸುವ ಅನುಭವವನ್ನು ನೀಡುವ ಉತ್ಸಾಹಭರಿತ ಆಚರಣೆಗಳನ್ನು ಸ್ಥಳೀಯರೊಂದಿಗೆ ಸೇರಿ ಅವರೊಂದಿಗೆ ವಿಜೆಹ್ರಾಭಣೆಯಿಂದ ಆಚರಿಸಿ.' : 
    'Experience the grandeur of the vibrant celebrations that bring Mandya to life. Join the locals in the lively celebrations where each festival is a vivid display of Mandya\'s rich cultural heritage, offering visitors an immersive experience of traditional customs and communal harmony.';

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
        }, 3000); // Change slide every 3 seconds
        return () => clearInterval(interval);
    }, []);

    const goToSlide = (index) => {
        setCurrentSlide(index);
    };

    const handleImageClick = (searchQuery) => {
    navigate(`/destination/category?search=${searchQuery}`);
  };

  const handlePopularDestinationClick = (destination) => {
    navigate(`/destination/detail?search=${destination}`);
  };

  const [hovered, setHovered] = useState(false);

  return (
    <div style= {{backgroundColor:'$f1f1f1'}}>
      <div className="hero-section">
        <div className="overlap">
          <div className="overlap-group">
          <div className="carousel" style={{height: '500px', objectFit: 'cover'}}>
          
          {images.map((image, index) => (
              <div
                  key={index}
                  className={`carousel-slide ${index === currentSlide ? 'active' : ''}`}
                  style={{ backgroundImage: `url(${image})` }}
              >
              </div>
          ))}
            <div className='carousel-top'/>
            <div className="carousel-indicators">
          {images.map((_, index) => (
              <button
                  key={index}
                  className={`indicator ${index === currentSlide ? 'active' : ''}`}
                  onClick={() => goToSlide(index)}
              ></button>
          ))}
      </div>
      </div>
      
            <div className="div" />
            <div className="frame">
              <div style={{marginLeft: '30px'}} >
                { isKannada ? (
                  <p className="discover-the-hidden">
                    <span className="span">ಮಂಡ್ಯದ </span>
                    <span className="text-wrapper">ಸೊಬಗನ್ನು ಅನ್ವೇಷಿಸಿ</span>
                    <span className="text-wrapper"> </span>
                  </p>
                  ) : 
                  (
                    <p className="discover-the-hidden">
                      <span className="text-wrapper">Discover the Hidden Charms of</span>
                      <span className="span">Mandya</span>
                    </p>
                  )
                }
                {!isMobile && <p className="p">{headerMandyaParagraph}
                </p>}
              </div>
            </div>
            <button className="CTA">
              <div className="text-wrapper-2" onClick={() => navigate(`/destination`)}>{startExploring}</div>
            </button>
            </div>
          </div>
      </div>
    
    <Box style={{ backgroundColor: '#F1F1F1', marginTop: '0px' }}>
      <div className={classes.homeBlock1} style ={{marginLeft: isMobile ? '10px' : '60px', marginRight: isMobile ? '10px' : '60px'}}>
        <div className={classes.heading}>
          <Typography variant="h4" style={{fontFamily: 'Montserrat, sans-serif'}} className={classes.underlinedHeading}> <b>{exploreMandyaHeading}</b> </Typography>
          <br></br>
          <br></br>
          <Typography variant="body1"  align="justify" style={{fontFamily: 'Montserrat, sans-serif', fontSize: '16px', fontWeight: '400', lineHeight: '28px'}}
          >{exploreMandyaParagraph}</Typography>
          <br></br>
        </div>
        <div>
        <Grid container spacing={2} cpxlassName={classes.imageGrid}>
          {isMobile ? <></> :
            <Grid item xs={12} sm={6} className={classes.imageItem}>
                <img style ={{height: '92%'}} src={natural_destination} onClick={() => handleImageClick('natural')} alt="Natural Attractions" className={classes.largeImage} />
                <Typography variant="h5" style={{width: '100%', background:'grey', color: 'white', marginTop: '-20px', paddingTop:'25px', paddingBottom: '13px', borderRadius:'10px', paddingLeft:'10px'}} > <b>{naturalAttrBtn}</b> </Typography>
            </Grid>
          }
          
          <Grid item xs={12} sm={6} container direction="column" spacing={2}>
            {isMobile ? 
            <Grid item className={classes.imageItem}>
              <div onClick={() => handleImageClick('natural')}>
                <img src={natural_destination} alt="Natural Attractions" className={classes.largeImage} />
                <Typography variant="h5" style={{width: '100%', background:'grey', color: 'white', marginTop: '-20px', paddingTop:'25px', paddingBottom: '10px', borderRadius:'10px', paddingLeft:'10px'}} > <b>{naturalAttrBtn}</b> </Typography>
              </div>
            </Grid>
            : <></>}
            <Grid item className={classes.imageItem}>
            <div onClick={() => handleImageClick('spiritual')}>
              <img src={temple} alt="Religious Places" className={classes.largeImage} />
              <Typography variant="h5" style={{width: '100%', background:'grey', color: 'white', marginTop: '-20px', paddingTop:'25px', paddingBottom: '10px', borderRadius:'10px', paddingLeft:'10px'}} > <b>{spiritualAttrBtn}</b> </Typography>
            </div>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={6} className={classes.imageItem}>
              <div onClick={() => handleImageClick('historical')}>
                <img style ={{paddiongBottom : '-10px'}} src={historical} alt="Historical Sites" className={classes.smallImage} />
                <Typography variant="h5" style={{width: '100%', background:'grey', color: 'white', marginTop: '-20px', paddingTop:'25px', paddingBottom: '10px', borderRadius:'10px', paddingLeft:'10px'}} > <b>{historicalAttrBtn}</b> </Typography>
              </div>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.imageItem}>
              <div onClick={() => handleImageClick('other')}>
                <img src={other} alt="Other Attractions" className={classes.smallImage} />
                <Typography variant="h5" style={{width: '100%', background:'grey', color: 'white', marginTop: '-20px', paddingTop:'25px', paddingBottom: '10px', borderRadius:'10px', paddingLeft:'10px'}} > <b>{otherAttrBtn}</b> </Typography>
              </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        </div>
        </div>
      </Box>


      <Box className={classes.heading} style={{marginLeft: isMobile ? '10px': '60px', marginRight: isMobile ? '10px': '60px', maxWidth: '100%'}}>
        <Typography variant="h4" className={classes.underlinedHeading}> <b>{popularDestinationHeader}</b> </Typography>
        <br></br>
        <br></br>
          <Typography variant="body1"  align="justify" style={{fontFamily: 'Montserrat, sans-serif', fontSize: '16px', fontWeight: '400',lineHeight: '28px'}}>{popularDestParagraph}</Typography>
        <br></br>

        <Grid container spacing={2} >
          <Grid item xs={12} sm={6} md={3}>
            <Card className={classes.card} onClick={() => handlePopularDestinationClick('28')}>
              <CardMedia
                className={classes.cardMedia}
                component="img"
                image={popular_1}
                title="Melukote"
              />
              <CardContent className={classes.cardContent} style={{height : isMobile ? 'auto' : '100px'}}>
                <Typography variant="h5" ><span className={classes.paragraph}>{melukoteHeader}</span></Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Card className={classes.card} onClick={() => handlePopularDestinationClick('1')}>
              <CardMedia
                className={classes.cardMedia}
                component="popular_2"
                image={popular_2}
                title="Kashi Vishwanatha temple"
              />
              <CardContent className={classes.cardContent} style={{height : isMobile ? 'auto' : '100px'}}>
                <Typography variant="h5"><span className={classes.paragraph} style={{textAlign: 'bottom'}}>{kashiHeader}</span></Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Card className={classes.card} onClick={() => handlePopularDestinationClick('7')}>
              <CardMedia
                className={classes.cardMedia}
                component="img"
                image={popular_3}
                title="GaganaChukki Falls"
              />
              <CardContent className={classes.cardContent} style={{height : isMobile ? 'auto' : '100px'}}>
                <Typography variant="h5"><span className={classes.paragraph}>{gaganachukkiHeader}</span></Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Card className={classes.card} onClick={() => handlePopularDestinationClick('10')}>
              <CardMedia
                className={classes.cardMedia}
                component="img"
                image={popular4} // Add your fourth place image here
                title="Bheemeshwaritemple"
              />
              <CardContent className={classes.cardContent} style={{height : isMobile ? 'auto' : '100px'}}>
                <Typography variant="h5"><span className={classes.paragraph}>{bheemeshwariHeader}</span></Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
  
  {/* Till here */}

      <div className={classes.heading} style ={{marginLeft: isMobile ? '10px' : '60px', marginRight: isMobile ? '10px' : '60px'}}>
        <Typography variant="h4" className={classes.underlinedHeading}> {getReadyToUnwind} </Typography>
        <br/>
        <br/>
        <br></br>
        <div style={{marginLeft: isMobile ? '0px' : '50px', marginRight: isMobile ? '10px' : '60px', maxWidth: '100%'}}>
          <MapComponent isKannada={isKannada}/>
        </div>
      </div>

      {/* Done till here */}

      <div className={classes.heading} style={{ marginLeft: isMobile ? '10px' : '60px'}}>
        <Box className={classes.heading}>
          <Typography variant="h4" className={classes.underlinedHeading}> <b>{upcomingFestivals}</b> </Typography>
          <br></br>
          <br></br>
        </Box>
        

        <div style={{display: 'flex', flexDirection: 'row', maxWidth: '100%'}}>
          <Typography variant="body1"  align="justify" style={{maxWidth: isMobile ? '100%' : '40%', marginRight: '20px', fontFamily: 'Montserrat, sans-serif', fontSize: '16px', fontWeight: '400', lineHeight: '28px'}}
          >{upComingFestivalsParagraph}</Typography>
          
          <div className={classes.eventsContainer} style={{marginLeft: isMobile ? '10px': '30px', marginRight: isMobile ? '10px': '60px'}} onClick={() => navigate(`/festivals`)}>
          
            {festivalData.map((event, index) => (
              <Card key={index} className={classes.eventCard}>
                <CardMedia
                  component="img"
                  image={event.image}
                  className={classes.eventImage}
                  title={event.name}
                />
                <CardContent className={classes.eventDetails}>
                  <Typography className={classes.eventTitle}><b>{isKannada ? event.nameKannada : event.name}</b></Typography>
                  {isMobile ? <></> : <Typography className={classes.eventDescription} style={{ WebkitLineClamp: 3, color: 'grey' }}>{isKannada ? event.descriptionKannada : event.description}</Typography> }
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </div>
      <br></br>
      <Box className={classes.iframeContainer}>
        <iframe
          title="Google Maps"
          width="100%"
          height="450"
          style={{ border: 0 }}
          loading="lazy"
          allowFullScreen
          src={mapSrc}
        ></iframe>
      </Box>
      <div style={{marginTop:'-10px'}}/>
    </div>
  );
};

export default Home;
