import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapContainer, GeoJSON, Marker, Popup, TileLayer } from 'react-leaflet';
import { useMediaQuery, useTheme } from "@mui/material";
import 'leaflet/dist/leaflet.css';
import axios from 'axios';
import mandyaGeoJSON from '../map/mandya_district.json';
import placesJson from '../Data/placesData.json';
import L from 'leaflet';
import '../pages/css/MapComponent.css';
import marker from '../Assets/marker3.png';

// Define the marker icon
const markerIcon = new L.Icon({
  iconUrl: marker,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});

const API_URL = 'http://ec2-3-105-71-196.ap-southeast-2.compute.amazonaws.com:8080/api/Place/getplaces';

const MapComponent = ({ isKannada }) => {
  const [selectedTaluk, setSelectedTaluk] = useState(null);
  const [placesData, setPlacesData] = useState([]);
  const [zoomLevel, setZoomLevel] = useState(10); // Default zoom level
  const [activeIndex, setActiveIndex] = useState(0); // Index of the active card
  const [selectedPlace, setSelectedPlace] = useState(placesJson[0]); // State to hold selected place
  const navigate = useNavigate();
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    // Fetch all places data from API
    const fetchData = async () => {
      try {
        const response = await axios.get(API_URL, {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
        });
        setPlacesData(response.data);
      } catch (error) {
        console.error('Error fetching places data:', error);
        console.log(placesJson);
        setPlacesData(placesJson);
      }
    };

    fetchData();

    // Adjust zoom level based on window size
    const handleResize = () => {
      if (window.innerWidth <= 480) {
        setZoomLevel(8); // Adjusted zoom for smaller screens
      } else if (window.innerWidth <= 768) {
        setZoomLevel(9.3); // Default zoom for tablet screens
      } else {
        setZoomLevel(10); // Default zoom for larger screens
      }
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onEachFeature = (feature, layer) => {
    const talukName = feature.properties.sdtname; // Access taluk name from GeoJSON properties
    // layer.bindPopup(talukName); // Bind taluk name to popup
    layer.on({
      click: (e) => {
        setSelectedTaluk(talukName);
        e.target.setStyle({
          fillColor: '#f1981a',
          fillOpacity: 0.8,
        });
      },
      mouseover: (e) => {
        if (selectedTaluk != talukName) {
          e.target.setStyle({
            fillColor: '#f1981a',
            fillOpacity: 0.3,
          });
        }
      },
      mouseout: (e) => {
        console.log(selectedTaluk);
        console.log(talukName);
        if (selectedTaluk != talukName) {
          e.target.setStyle({
            fillColor: '#d3d3d3',
            fillOpacity: 0.5,
          });
        }
      }
    });
  };

  const style = (feature) => {
    return {
      fillColor: selectedTaluk === feature.properties.sdtname ? '#f1981a' : '#d3d3d3',
      fillOpacity: 0.5,
      color: 'black',
      weight: 0.5,
    };
  };

  const handleExploreClick = () => {
    if (!selectedTaluk) {
      const message = isKannada ? 'ದಯವಿಟ್ಟು ಮೊದಲಿಗೆ ಒಂದು ತಾಲೂಕನ್ನು ಆಯ್ಕೆಮಾಡಿ' : 'Please select a taluk first';
      alert(message);
    } else {
      navigate(`/destination/category?search=${selectedTaluk}`);
    }
  };

  const handlePlaceClick = (itemId) => {
    const clickedPlace = placesData.find(place => place.id === itemId);
    setSelectedPlace(clickedPlace);
    console.log(selectedPlace);
  };

  // Parse coordinates from Google Maps URL
  const parseCoordinatesFromMapsUrl = (mapsUrl) => {
    const regex = /@(-?\d+\.\d+),(-?\d+\.\d+)/;
    const match = mapsUrl.match(regex);
    console.log(match);
    if (match && match.length === 3) {
      const latitude = parseFloat(match[1]);
      const longitude = parseFloat(match[2]);
      return { latitude, longitude };
    }
    return null;
  };

  // Filter places based on selected taluk
  const filteredPlaces = selectedTaluk ? placesData.filter(place => place.taluk === selectedTaluk) : [];

  // Filtered places with valid mapsUrl and coordinates parsed
  const filteredPlacesWithCoordinates = filteredPlaces.filter(place => {
    return place.mapsUrl && parseCoordinatesFromMapsUrl(place.mapsUrl);
  });

  // Array to hold markers based on places data
  const markers = filteredPlacesWithCoordinates.map(place => {
    const { latitude, longitude } = parseCoordinatesFromMapsUrl(place.mapsUrl);
    return {
      id: place.id,
      position: [latitude, longitude],
      name: isKannada ? place.nameKannada : place.name,
    };
  });

  // Function to handle swiping
  const handleChangeIndex = (index) => {
    setActiveIndex(index);
  };

  // Rendered markers for places
  const renderedMarkers = markers.map(marker => (
    <Marker
      style = {{objectFit:'cover'}}
      key={marker.id}
      position={marker.position}
      icon={markerIcon}
      eventHandlers={{
        click: () => {
          handlePlaceClick(marker.id);
        }
      }}
    >
      <Popup>
        <div>
          <button className="explore-button" style={{width: '100%'}} onClick={() => navigate(`/destination/detail?search=${marker.id}`)}>{marker.name}</button>
          <button className="explore-button" style={{width: '100%', marginTop:'10px', background:'#c3c3c3', color:'#000000'}} onClick={() => window.open(`https://www.google.com/maps/embed/v1/place?key=AIzaSyD6Kc3F30Wi9Egb-DCzs3ToaksuFGEJoxs&q=${marker.name} mandya district`, '_blank')}><b>Navigate</b></button>
        </div>
        </Popup>
    </Marker>
  ));

  return (
    <div className="main-container">
      <div className="left-panel" style={{ background: '#dddddd' }}>
        {selectedPlace && (
          <div className="place-card" onClick={() => navigate(`/destination/detail?search=${selectedPlace.id}`)}>
            <div className="image-container">
              {selectedPlace.images && selectedPlace.images.length > 0 ? (
                <img
                  src={selectedPlace.images[0]}
                  alt={selectedPlace.name}
                  style={{ height: isMobile? '250px' : '600px' }}
                  className="place-image"
                />
              ) : (
                <div className="no-image">No Image Available</div>
              )}
              <div className="place-details">
                <h3 className="place-name">{isKannada ? selectedPlace.nameKannada : selectedPlace.name}</h3>
              </div>
            </div>
          </div>
        )}
        <button className="explore-button" onClick={handleExploreClick} style={{ marginLeft: '120px', marginTop: '15px' }}>
          {isKannada
            ? selectedTaluk
              ? `${selectedTaluk} ಅನ್ವೇಷಿಸಿ`
              : 'ಇದನ್ನು ಅನ್ವೇಷಿಸಿ'
            : selectedTaluk
              ? `Explore ${selectedTaluk}`
              : 'Explore Now'}
        </button>
      </div>
      <div className="right-panel">

        <MapContainer
          style={{ height: '100%', width: '100%', maxWidth: '100vw' }}
          center={isMobile ? [12.6364, 76.8206] :[12.6364, 76.7506]}
          zoom={isMobile ? 9 : 10}
          scrollWheelZoom={false}
          doubleClickZoom={false}
          zoomControl={false}
          dragging={false}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            maxZoom={19}
          />
          <GeoJSON data={mandyaGeoJSON} style={style} onEachFeature={onEachFeature} />

          {renderedMarkers}

        </MapContainer>
      </div>
    </div>
  );
};

export default MapComponent;
