import PropTypes from 'prop-types';
import React, { useReducer } from 'react';
import { IconComponentNode } from './IconComponentNode';
import './style.css';

export const Component = ({
  property,
  imageClassName,
  explore = 'Explore',
  image = 'image-2.png',
  text = 'Gumbaz Burial Chamber',
  text1 = 'The Gumbaz Burial Chamber is a notable historical site located in the town of Srirangapatna, which is in the Mandya district of Karnataka, India. Magnificent structure is the mausoleum of Tipu Sultan, the famous...',
  icon = <IconComponentNode className="vuesax-linear-arrow" color="#f1981a" style={{color:'#f1981a'}} />,
  onClick, // Add the onClick prop
}) => {
  const [state, dispatch] = useReducer(reducer, {
    property: property || 'default',
  });

  return (
    <div
      className="component"
      onMouseLeave={() => {
        dispatch('mouse_leave');
      }}
      onMouseEnter={() => {
        dispatch('mouse_enter');
      }}
      onClick={onClick} // Attach the onClick event
    >
      <img
        className={`image ${imageClassName}`}
        alt="Image"
        src={image}
      />
      <div className={`frame ${state.property}`}>
        <div className="div">
          <div className="gumbaz-burial">{text}</div>
        </div>
        <p className="the-gumbaz-burial truncated">{text1}</p>
        <div className="frame-2">
          <div className="text-wrapper-2" style={{color: '#f1981a'}}>{explore}</div>
          {/* {icon} */}
        </div>
      </div>
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case 'mouse_enter':
      return {
        ...state,
        property: 'hover',
      };
    case 'mouse_leave':
      return {
        ...state,
        property: 'default',
      };
    default:
      return state;
  }
}

Component.propTypes = {
  property: PropTypes.oneOf(['hover', 'default']),
  image: PropTypes.string,
  text: PropTypes.string,
  text1: PropTypes.string,
  onClick: PropTypes.func, // Add onClick prop type
};
