import React from "react";
import {
  Box,
  Card,
  Button,
  useMediaQuery,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import {
  needAnyHelpText, contactUsText, location,
  locationText,
  phoneNumber,
  phoneNumberText, emailAddress, emailAddressText, leaveAMessage
} from './contactData';
import LocationImage from '../../Assets/Location.png';
import Phone from '../../Assets/phone.png';
import Mail from '../../Assets/mail.png';
import TextField from '@mui/material/TextField';

const useStyles = makeStyles((theme) => ({
  contactUsImage: {
    width: '100%',
    height: '400px',
  },
  contactUsBlocks: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '75px',
    marginTop: '25px',
    paddingBottom: '100px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginLeft: '10px',
    },
  },
  contactUsText: {
    fontFamily: 'Urbanist',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '28px',
    textAlign: 'left',
  },
  contactUsFirstBlock: {
    maxWidth: '603px',
    marginBottom: '20px',
  },
  contactUsSecondBlock: {
    backgroundColor: '#FFFFFF',
    width: '565px',
    height: '666px',
    padding: '30px 0px 0px 50px',
    paddingRight: '50px',
    marginLeft: '30px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px',
      width: '100%',
      padding: '20px 30px',
      marginTop: '20px',
      marginRight: '20px',
    },
  },
  contactDetailsBlock: {
    backgroundColor: '#FFFFFF',
    minWidth: '603px',
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
  },
  detailsStyles: {
    fontFamily: 'Urbanist',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '28px',
    textAlign: 'left',
  },
  detailsHeading: {
    fontFamily: 'Urbanist',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '24px',
    textAlign: 'left',
    textTransform: 'uppercase',
    color: '#47475F',
    paddingLeft: '10px'
  },
  underlinedHeading: {
    fontFamily: 'Montserrat, sans-serif',
    marginBottom: '10px',
    fontSize: '28px',
    color: 'black',
    position: 'relative',
    textDecoration: 'none',
    display: 'inline-block',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: -2,
      width: '60%',
      borderBottom: '4px solid #FFD700 !important',
    },
  },
}));

function Contacts() {
  const classes = useStyles();
  const theme = useTheme();
  
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div style={{ background: '#F1F1F1', marginTop: '25px', paddingRight: '10px'}}>
      <h2 className={classes.underlinedHeading} style={{ marginLeft: isMobile ? '10px' : '75px', marginRight: isMobile ? '10px' : '75px' }}>Contact Us</h2>
      <Box className={classes.contactUsBlocks}>
        <Box className={classes.contactUsFirstBlock}>
          <h3>{needAnyHelpText}</h3>
          <p className={classes.contactUsText}>{contactUsText}</p>

          <Card className={classes.contactDetailsBlock}>
            <Box style={{ margin: '10px', maxWidth: '505px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '15px' }}>
                <img style={{ width: '24px', height: '24px' }} src={LocationImage} alt='location' />
                <span className={classes.detailsHeading}>{location}</span>
              </div>
              <p className={classes.detailsStyles}>{locationText}</p>
            </Box>
          </Card>

          <Card className={classes.contactDetailsBlock}>
            <Box style={{ margin: '10px', maxWidth: '505px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '15px' }}>
                <img style={{ width: '24px', height: '24px' }} src={Phone} alt='location' />
                <span className={classes.detailsHeading}>{phoneNumber}</span>
              </div>
              <p className={classes.detailsStyles}>{phoneNumberText}</p>
            </Box>
          </Card>

          <Card className={classes.contactDetailsBlock}>
            <Box style={{ margin: '10px', maxWidth: '505px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '15px' }}>
                <img style={{ width: '24px', height: '24px' }} src={Mail} alt='location' />
                <span className={classes.detailsHeading}>{emailAddress}</span>
              </div>
              <p className={classes.detailsStyles}>{emailAddressText}</p>
            </Box>
          </Card>
        </Box>

        <Card className={classes.contactUsSecondBlock} style={{marginLeft: isMobile ? '0px' : '', marginRight: isMobile ? '10px' : ''}}>
          <h2>{leaveAMessage}</h2>

          <Box style={{ marginTop: '20px' }}>
            <TextField
              id="outlined-multiline-flexible"
              label="Enter your name"
              multiline
              maxRows={4}
              style={{ width: '100%' }}
            />
          </Box>
          <Box style={{ marginTop: '20px' }}>
            <TextField
              id="outlined-multiline-flexible"
              label="Enter your email address"
              multiline
              maxRows={4}
              style={{ width: '100%' }}
            />
          </Box>
          <Box style={{ marginTop: '20px' }} >
            <TextField
              id="outlined-multiline-flexible"
              label="Enter your phone number"
              multiline
              maxRows={4}
              style={{ width: '100%' }}
            />
          </Box>
          <Box style={{ marginTop: '20px' }}>
            <TextField
              id="outlined-multiline-flexible"
              label="Enter your message"
              multiline
              maxRows={14}
              style={{ width: '100%' }}
            />
          </Box>

          <Button style={{ marginTop: '60px', backgroundColor: '#2B3030', width: '100%' }} variant="contained">
            Submit
          </Button>
        </Card>
      </Box>
    </div>
  );
}

export default Contacts;
