const needAnyHelpText = 'Need any help?';
const contactUsText = 'Contact us today, and our dedicated team will provide swift and tailored assistance to meet your needs.'
const location = 'Location';
const locationText = 'Mandya Tourism Department, PES College Campus, Mandya, Karnataka 571401';
const phoneNumber = 'Phone Number';
const phoneNumberText = '08232-23877';
const emailAddress = 'Email Address';
const emailAddressText = 'info@mandyatourism.com'
const leaveAMessage = 'Leave a Message';

export {
    needAnyHelpText,
    contactUsText,
    location,
    locationText,
    phoneNumber,
    phoneNumberText,
    emailAddress,
    emailAddressText,
    leaveAMessage
}