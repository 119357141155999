import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import Navbar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./pages/Home/home";
import Destination from "./pages/Destination/destination";
import Festivals from "./pages/Festivals/festivals";
import Contacts from "./pages/Contacts/contacts";
import TravelGuide from "./pages/TravelGuide/travelGuide";
import Gallery from "./pages/Gallery/gallery";
import AboutUs from "./pages/AboutUs/aboutUs";
import DestinationCategory from "./pages/Destination/destinationCategory";
import DestinationDetails from "./pages/Destination/destinationDetails";
import theme from './theme';
import ArtsAndCuisine from "./pages/ArtsAndCusine/ArtAndCusine";
import ScrollToTop from "./ScrollToTop";

function App() {
  const [isKannada, setIsKannada] = useState(false);

  const toggleLanguage = () => {
      setIsKannada(prevIsKannada => !prevIsKannada);
  };

  return (
    <ThemeProvider theme={theme}>
    <BrowserRouter>
      <ScrollToTop />
      <Navbar isKannada={isKannada} toggleLanguage={toggleLanguage} />
      <div style={{ paddingTop: '80px' }}>
        <Routes>
          <Route index element={<Home isKannada={isKannada} />} />
          <Route path="destination" element={<Destination isKannada={isKannada} />} />
          <Route path="destination/category" element={<DestinationCategory isKannada={isKannada} />} />
          <Route path="destination/detail" element={<DestinationDetails isKannada={isKannada} />} />
          <Route path="festivals" element={<Festivals isKannada={isKannada} />} />
          <Route path="artsAndCusine" element={<ArtsAndCuisine isKannada={isKannada} />} />
          <Route path="contacts" element={<Contacts isKannada={isKannada} />} />
          <Route path="travelGuide" element={<TravelGuide isKannada={isKannada} />} />
          <Route path="gallery" element={<Gallery isKannada={isKannada} />} />
          <Route path="aboutUs" element={<AboutUs isKannada={isKannada} />} />
        </Routes>
      </div>
      <Footer  isKannada={isKannada}/>
    </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
