// src/data.js
const data = {
    en: {
      toursAndTravels: [
        {
          name: "Paryatana Tours and Travels",
          address: "#15, 16, 17, 1st Floor, Vijayalakshmi Complex, Hosahalli Circle, 100Ft Road, Mandya",
          phone: "+91 9448649563",
          email: "paryatanatt@gmail.com",
        },
        {
          name: "M/S Nudibharathi Seva Trust, Tours and Travels",
          address: "Nudi Bharathi Building, Guttalu Road, Arakeshwara Nagara, Mandya",
          phone: "+91 9448345343",
          email: "nudibharathi@gmail.com",
        },
      ],
      homestays: [
        {
          name: "Chukkimane Home stay",
          address: "Balakwadi, Near Javanagahalli, Malavalli Taluk, Mandya District",
          phone: "+91 9880467402",
          email: "chukkimane@gmail.com",
          website: "https://www.chukkimane.com",
        },
        {
          name: "Green Peace Home Stay",
          address: "Ganalu Falls Road, Halguru Hobli, Malavalli Taluk, Mandya District",
          phone: "+91 9686202944",
          email: "gangadhar96@gmail.com",
        },
        {
          name: "Jai Home Stay",
          address: "Ballegere, Malavalli Taluk, Mandya District",
          phone: "+91 9880197928",
          email: "jairajf@gmail.com",
        },
        {
          name: "Sunset Bay Home Stay",
          address: "Chikkarayara Halli, Pandavapura Taluk, Mandya District",
          phone: "+91 8618001236",
          email: "sunsetbayindia@gmail.com",
        },
        {
          name: "Atmaveda Homestay",
          address: "Paschimavahini, Srirangapatna, Mandya District",
          phone: "+91 9902756779  /  +91 70194 51252",
          email: "atmaveda.booking@gmail.com",
          website: "https://atmaveda.in",
        },
      ],
      hotelsAndResorts: [
        {
          name: "Hotel Lakshadeep",
          address: "Shivanasamudra, Malavalli Taluk, Mandya District",
          phone: "+91 8884423456",
        },
        {
          name: "Hotel HM Resort",
          address: "Palahalli Village, Srirangapatna Taluk, Mandya District",
          phone: "+91 7338425009",
        },
        {
          name: "Hotel Mahadeshwara Boarding and Lodging",
          address: "Paschimavahini, Srirangapatna Taluk, Mandya District",
          phone: "+91 9448930232",
        },
        {
          name: "River Side Holiday Resort",
          address: "Paschimavahini, Srirangapatna Taluk, Mandya District",
          phone: "+91 9448930232",
        },
        {
          name: "Hotel Yogadhama",
          address: "Bommuru Agrahara, Srirangapatna Taluk, Mandya District",
          phone: "+91 9448282113",
        },
        {
          name: "Shubha Resort",
          address: "Hosa Ananduru Village, Srirangapatna Taluk, Mandya District",
          phone: "+91 7338676751",
        },
        {
          name: "Nimishambha Boarding and Lodging",
          address: "Srirangapatna Taluk, Mandya District",
          phone: "+91 9535901235",
        },
        {
          name: "Jaladhaare Resort",
          address: "Bommuru Agrahara, Srirangapatna Taluk, Mandya District",
          phone: "+91 9242133907",
        },
        {
          name: "Hotel Mayura River View",
          address: "Srirangapatna Taluk, Mandya District",
          phone: "0823-252114",
        },
        {
          name: "Hotel Mayura Cauvery",
          address: "KRS, Srirangapatna Taluk, Mandya District",
          phone: "+91 8970650022",
        },
        {
          name: "Sri Nanjundeshwara Residency",
          address: "Belluru Cross, Nagamangala Taluk, Mandya District",
          phone: "+91 9448047522",
        },
        {
          name: "Hotel Kalinga Tovern",
          address: "Kalinganahalli, Nagamangala Taluk, Mandya District",
          phone: "+91 9448957404",
        },
        {
          name: "Hotel Dhruvathare",
          address: "Kalinganahalli, Nagamangala Taluk, Mandya District",
          phone: "+91 9845707788",
        },
        {
          name: "Hotel Leela Residency",
          address: "Beeruvalli village, KRPete Taluk, Mandya District",
          phone: "+91 9448488783",
        },
      ],
    },
    kn: {
      toursAndTravels: [
        {
          name: "ಪರ್ಯಾಯನಾ ಟೂರ್ಸ್ ಮತ್ತು ಟ್ರಾವೆಲ್ಸ್",
          address: "#15, 16, 17, 1 ನೇ ಮಹಡಿ, ವಿಜಯಲಕ್ಷ್ಮಿ ಕಾಂಪ್ಲೆಕ್ಸ್, ಹೊಸಹಳ್ಳಿ ವೃತ್ತ, 100 ಅಡಿ ರಸ್ತೆ, ಮಂಡ್ಯ",
          phone: "+91 9448649563",
          email: "paryatanatt@gmail.com",
        },
        {
          name: "M/S ನುಡಿಭಾರತಿ ಸೇವಾ ಟ್ರಸ್ಟ್, ಟೂರ್ಸ್ ಮತ್ತು ಟ್ರಾವೆಲ್ಸ್",
          address: "ನುಡಿ ಭಾರತಿ ಕಟ್ಟಡ, ಗುತ್ತಲು ರಸ್ತೆ, ಅರಕೇಶ್ವರ ನಗರ, ಮಂಡ್ಯ",
          phone: "+91 9448345343",
          email: "nudibharathi@gmail.com",
        }, {
            name: "M/S ನುಡಿಭಾರತಿ ಸೇವಾ ಟ್ರಸ್ಟ್, ಟೂರ್ಸ್ ಮತ್ತು ಟ್ರಾವೆಲ್ಸ್",
            address: "ನುಡಿ ಭಾರತಿ ಕಟ್ಟಡ, ಗುತ್ತಲು ರಸ್ತೆ, ಅರಕೇಶ್ವರ ನಗರ, ಮಂಡ್ಯ",
            phone: "+91 9448345343",
            email: "nudibharathi@gmail.com",
          },
      ],
      homestays: [
        {
          name: "ಚುಕ್ಕಿಮನೆ ಹೋಮ್ ಸ್ಟೇ",
          address: "ಬಲಕವಾಡಿ, ಜವನಗಹಳ್ಳಿ ಹತ್ತಿರ, ಮಳವಳ್ಳಿ ತಾಲ್ಲೂಕು, ಮಂಡ್ಯ ಜಿಲ್ಲೆ",
          phone: "+91 9880467402",
          email: "chukkimane@gmail.com",
          website: "https://www.chukkimane.com",
        },
        {
          name: "ಗ್ರೀನ್ ಪೀಸ್ ಹೋಮ್ ಸ್ಟೇ",
          address: "ಗಾನಾಳು ಫಾಲ್ಸ್ ರಸ್ತೆ, ಹಲಗೂರು ಹೋಬಳಿ, ಮಳವಳ್ಳಿ ತಾಲ್ಲೂಕು, ಮಂಡ್ಯ ಜಿಲ್ಲೆ",
          phone: "+91 9686202944",
          email: "gangadhar96@gmail.com",
        },
        {
          name: "ಜೈ ಹೋಮ್ ಸ್ಟೇ",
          address: "ಬಳ್ಳೇಗೆರೆ, ಮಳವಳ್ಳಿ ತಾಲ್ಲೂಕು, ಮಂಡ್ಯ ಜಿಲ್ಲೆ",
          phone: "+91 9880197928",
          email: "jairajf@gmail.com",
        },
        {
          name: "ಸನ್ಸೆಟ್ ಬೇ ಹೋಮ್ ಸ್ಟೇ",
          address: "ಚಿಕ್ಕರಾಯರ ಹಳ್ಳಿ, ಪಾಂಡವಪುರ ತಾಲ್ಲೂಕು, ಮಂಡ್ಯ ಜಿಲ್ಲೆ",
          phone: "+91 8618001236",
          email: "sunsetbayindia@gmail.com",
        },
        {
          name: "ಆತ್ಮವೇದ ಹೋಂಸ್ಟೇ",
          address: "ಪಶ್ಚಿಮವಾಹಿನಿ, ಶ್ರೀರಂಗಪಟ್ಟಣ, ಮಂಡ್ಯ ಜಿಲ್ಲೆ",
          phone: "+91 9902756779 / +91 70194 51252",
          email: "atmaveda.booking@gmail.com",
          website: "https://atmaveda.in",
        },
      ],
      hotelsAndResorts: [
        {
          name: "ಹೋಟೆಲ್ ಲಕ್ಷದೀಪ",
          address: "ಶಿವನಸಮುದ್ರ, ಮಳವಳ್ಳಿ ತಾಲೂಕು, ಮಂಡ್ಯ ಜಿಲ್ಲೆ",
          phone: "+91 8884423456",
        },
        {
          name: "ಹೋಟೆಲ್ HM ರೆಸಾರ್ಟ್",
          address: "ಪಾಲಹಳ್ಳಿ ಗ್ರಾಮ, ಶ್ರೀರಂಗಪಟ್ಟಣ ತಾಲೂಕು, ಮಂಡ್ಯ ಜಿಲ್ಲೆ",
          phone: "+91 7338425009",
        },
        {
          name: "ಹೋಟೆಲ್ ಮಹದೇಶ್ವರ ಬೋರ್ಡಿಂಗ್ ಮತ್ತು ಲಾಡ್ಜಿಂಗ್",
          address: "ಪಶ್ಚಿಮವಾಹಿನಿ, ಶ್ರೀರಂಗಪಟ್ಟಣ ತಾಲೂಕು, ಮಂಡ್ಯ ಜಿಲ್ಲೆ",
          phone: "+91 9448930232",
        },
        {
          name: "ರಿವರ್ ಸೈಡ್ ಹಾಲಿಡೇ ರೆಸಾರ್ಟ್",
          address: "ಪಶ್ಚಿಮವಾಹಿನಿ, ಶ್ರೀರಂಗಪಟ್ಟಣ ತಾಲೂಕು, ಮಂಡ್ಯ ಜಿಲ್ಲೆ",
          phone: "+91 9448930232",
        },
        {
          name: "ಹೋಟೆಲ್ ಯೋಗಧಾಮ",
          address: "ಬೊಮ್ಮೂರು ಅಗ್ರಹಾರ, ಶ್ರೀರಂಗಪಟ್ಟಣ ತಾಲೂಕು, ಮಂಡ್ಯ ಜಿಲ್ಲೆ",
          phone: "+91 9448282113",
        },
        {
          name: "ಶುಭಾ ರೆಸಾರ್ಟ್",
          address: "ಹೊಸ ಆನಂದೂರು ಗ್ರಾಮ, ಶ್ರೀರಂಗಪಟ್ಟಣ ತಾಲೂಕು, ಮಂಡ್ಯ ಜಿಲ್ಲೆ",
          phone: "+91 7338676751",
        },
        {
          name: "ನಿಮಿಷಾಂಭ ಬೋರ್ಡಿಂಗ್ ಮತ್ತು ಲಾಡ್ಜಿಂಗ್",
          address: "ಶ್ರೀರಂಗಪಟ್ಟಣ ತಾಲೂಕು, ಮಂಡ್ಯ ಜಿಲ್ಲೆ",
          phone: "+91 9535901235",
        },
        {
            name: "ಹೋಟೆಲ್ ಜಲಧಾರೆ ರೆಸಾರ್ಟ್",
            address: "ಬೊಮ್ಮೂರು ಅಗ್ರಹಾರ, ಶ್ರೀರಂಗಪಟ್ಟಣ ತಾಲೂಕು, ಮಂಡ್ಯ ಜಿಲ್ಲೆ",
            phone: "+91 9242133907",
          },
          {
            name: "ಹೋಟೆಲ್ ಮಯೂರ ರಿವರ್ ವ್ಯೂ",
            address: "ಶ್ರೀರಂಗಪಟ್ಟಣ ತಾಲ್ಲೂಕು, ಮಂಡ್ಯ ಜಿಲ್ಲೆ",
            phone: "0823-252114",
          },
          {
            name: "ಹೋಟೆಲ್ ಮಯೂರ ಕಾವೇರಿ",
            address: "KRS, ಶ್ರೀರಂಗಪಟ್ಟಣ ತಾಲ್ಲೂಕು, ಮಂಡ್ಯ ಜಿಲ್ಲೆ",
            phone: "+91 8970650022",
          },
          {
            name: "ಶ್ರೀ ನಂಜುಂಡೇಶ್ವರ ರೆಸಿಡೆನ್ಸಿ",
            address: "ಬೆಳ್ಳೂರು ಕ್ರಾಸ್, ನಾಗಮಂಗಲ ತಾಲ್ಲೂಕು, ಮಂಡ್ಯ ಜಿಲ್ಲೆ",
            phone: "+91 9448047522",
          },
          {
            name: "ಹೋಟೆಲ್ ಕಳಿಂಗ ಟವರ್ನ್",
            address: "ಕಳಿಂಗನಹಳ್ಳಿ, ನಾಗಮಂಗಲ ತಾಲ್ಲೂಕು, ಮಂಡ್ಯ ಜಿಲ್ಲೆ",
            phone: "+91 9448957404",
          },
          {
            name: "ಹೋಟೆಲ್ ಧ್ರುವತಾರೆ",
            address: "ಕಾಳಿಂಗನಹಳ್ಳಿ, ನಾಗಮಂಗಲ ತಾಲ್ಲೂಕು, ಮಂಡ್ಯ ಜಿಲ್ಲೆ",
            phone: "+91 9845707788",
          },
          {
            name: "ಹೋಟೆಲ್ ಲೀಲಾ ರೆಸಿಡೆನ್ಸಿ",
            address: "ಬೀರುವಳ್ಳಿ ಗ್ರಾಮ, ಕೆ.ಆರ್.ಪೇಟೆ ತಾಲೂಕು, ಮಂಡ್ಯ ಜಿಲ್ಲೆ",
            phone: "+91 9448488783",
          },          
      ],
    },
  };
  
  export default data;
  