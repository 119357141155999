import React, { useState, useEffect } from 'react';
import { Box, Button, useTheme, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import axios from 'axios';
import { placesData } from "./destinationData";

const useStyles = makeStyles((theme) => ({
  destinationDetailBlock: {
    marginLeft: '50px',
    marginRight: '50px',
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#EAEAF1CC',
    minHeight: '250px',
    justifyContent: 'space-between',
  },
  headingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  heading: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '32px',
    fontWeight: '700',
    marginBottom: '20px',
  },
  description: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '28px',
    marginBottom: '20px',
  },
  button: {
    backgroundColor: '#808080', // Grey color
    color: '#fff',
    '&:hover': {
      backgroundColor: '#696969', // Darker grey color
    },
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '16px',
    fontWeight: '700',
    padding: '10px 20px',
    textTransform: 'none',
    borderRadius: '8px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  }, 
  underlinedHeading: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize : '28px',
    color: 'black', // Set font color to black
    position: 'relative', // Required for positioning pseudo-element
    textDecoration: 'none', // Remove default underline
    display: 'inline-block', // Ensure the pseudo-element respects the text width
    '&::after': {
      content: '""', // Required content for pseudo-element
      position: 'absolute',
      left: 0,
      bottom: -2,
      width: '60%', // Set width of the underline to 100% of the text width
      borderBottom: '4px solid #FFD700 !important', // Define double underline
    },
  },
}));

const DestinationDetails = ({ isKannada }) => {
  const classes = useStyles();
  const [destination, setDestination] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const placeId = new URLSearchParams(window.location.search).get('search');
  const [mapUrl, setMapUrl] = useState('https://www.google.com/maps/embed/v1/place?key=AIzaSyD6Kc3F30Wi9Egb-DCzs3ToaksuFGEJoxs&q=');

  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const fetchDestination = async () => {
      try {
        const response = await axios.get(`http://ec2-3-105-71-196.ap-southeast-2.compute.amazonaws.com:8080/api/Place/getplace/${placeId}`);
        setDestination(response.data);
        setMapUrl(`https://www.google.com/maps/embed/v1/place?key=AIzaSyD6Kc3F30Wi9Egb-DCzs3ToaksuFGEJoxs&q=${response.data.name} mandya district`);
      } catch (error) {
        console.log(placesData.filter(place => place.id == placeId));
        const dest = placesData.filter(place => place.id == placeId);
        console.log(dest);
        setDestination(dest[0]);
        setMapUrl(`https://www.google.com/maps/embed/v1/place?key=AIzaSyD6Kc3F30Wi9Egb-DCzs3ToaksuFGEJoxs&q=${dest[0].name} mandya district`);
        console.error('Error fetching destination data:', error);
      }
    };

    fetchDestination();
  }, [placeId]);

  useEffect(() => {
    if (destination && destination.images && destination.images.length > 0) {
      const interval = setInterval(() => {
        setCurrentSlide(prevSlide => (prevSlide + 1) % destination.images.length);
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [destination]);

  const handleLocateOnMaps = () => {
    if (destination) {
      window.open(destination.mapsUrl, '_blank');
    }
  };

  return (
    <div>
      <section className="home" id="home">
        <div className="carousel" style = {{height: isMobile ? '300px' : ' 500px', objectFit: 'cover'}}>
          {destination && destination.images && destination.images.map((image, index) => (
            <div
              key={index}
              className={`carousel-slide ${index === currentSlide ? 'active' : ''}`}
              style={{ backgroundImage: `url(${image})` }}
            />
          ))}
        </div>
        {destination && destination.images && destination.images.length > 0 && (
          <div className="carousel-indicators">
            {destination.images.map((_, index) => (
              <button
                key={index}
                className={`indicator ${index === currentSlide ? 'active' : ''}`}
                onClick={() => setCurrentSlide(index)}
              />
            ))}
          </div>
        )}
      </section>

      <Box style={{ marginLeft: isMobile ? '10px' : '50px', marginRight:isMobile? '10px' : '50px', marginTop: '20px' }}>
        <Box className={classes.headingContainer}>
          <h3 className={classes.underlinedHeading}>{destination ? (isKannada ? destination.nameKannada : destination.name) : 'Loading...'}</h3>
          <Button
            className={classes.button}
            onClick={handleLocateOnMaps}
            startIcon={<LocationOnIcon />}
          >
            Locate on Maps
          </Button>
        </Box>
        <p className={classes.description}>{destination ? (isKannada ? destination.descriptionKannada : destination.description) : 'Loading...'}</p>
      </Box>

      <Box className={classes.iframeContainer}>
        <iframe
          width="100%"
          height="450"
          style={{ border: 0 }}
          loading="lazy"
          allowFullScreen
          src={mapUrl}>
        </iframe>
      </Box>

    </div>
  );
}

export default DestinationDetails;
