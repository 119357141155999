import React from "react";
import { Box, Typography, Grid, useTheme, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Vision from './../../Assets/AboutUs/image.png';
import Mandya from './../../Assets/AboutUs/Melukote.jpg';
import Mission from './../../Assets/AboutUs/SriRangapattanaDasara.jpg';
import Structure from '../../Assets/structure.png';
import Table from '../../Assets/table.png';
import banner from '../../Assets/Banners/AboutUs.jpg'
import bannerKannada from '../../Assets/Banners/AboutUsKannada.jpg'

const useStyles = makeStyles((theme) => ({
  missionVision: {
    marginTop: '80px',
    marginLeft: '133px',
    maxWidth: '1400px',
    [theme.breakpoints.down('sm')]: {
      margin: '15px',
    },
  },
  mission: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '100px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingTop: '50px',
    },
  },
  vision: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  whyVisitMandya: {
    marginTop: '80px',
    marginLeft: '133px',
    marginBottom: '80px',
    [theme.breakpoints.down('sm')]: {
      margin: '15px',
    },
  },
  paragraph: {
    fontFamily: 'Montserrat, Arial, sans-serif',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '28px',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: '24px',
    },
  },
  secondaryFooter: {
    margin: '30px',
    display: 'flex',
    flexDirection: 'row',
    marginTop: '60px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      margin: '15px',
    },
  },
  imageSection: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  imageWrapper: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  sectionTitle: {
    fontFamily: 'Montserrat, Arial, sans-serif',
  },
  sectionContent: {
    fontFamily: 'Montserrat, Arial, sans-serif',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '28px',
    textAlign: 'left',
  },
  aboutUsImageContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  aboutUsImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  embraceYourJourneyContainer: {
    position: 'absolute',
    top: '50%',
    width: '45%',
    transform: 'translateY(-50%)',
    background: 'linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
    padding: '10px',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      top: 'auto',
      right: 'auto',
      transform: 'none',
      backgroundColor: 'transparent',
      color: 'black',
      padding: '0',
    },
  },
  underlinedHeading: {
    fontFamily: 'Montserrat, sans-serif',
    marginBottom: '10px',
    fontSize: '28px',
    color: 'black',
    position: 'relative',
    textDecoration: 'none',
    display: 'inline-block',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: -2,
      width: '60%',
      borderBottom: '4px solid #FFD700 !important',
    },
  },
}));

function AboutUs({ isKannada }) {
  const classes = useStyles();

  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const aboutMandya = isKannada ? 'ಮಂಡ್ಯದ ಬಗ್ಗೆ' : 'About Mandya';
  const aboutMandyaPara = isKannada ? 'ಕರ್ನಾಟಕದ ಹೃದಯಭಾಗದಲ್ಲಿ ನೆಲೆಸಿರುವ ರೋಮಾಂಚಕ ಜಿಲ್ಲೆ, ಶ್ರೀಮಂತ ಸಾಂಸ್ಕೃತಿಕ ಪರಂಪರೆ, ಐತಿಹಾಸಿಕ ಮಹತ್ವ ಮತ್ತು ನೈಸರ್ಗಿಕ ಸೌಂದರ್ಯಕ್ಕೆ ಹೆಸರುವಾಸಿಯಾಗಿರುವ ಮಂಡ್ಯ ಜಿಲ್ಲೆಗೆ ಸುಸ್ವಾಗತ. ಇಲ್ಲಿಯ ವಿಸ್ತಾರವಾದ ಕಬ್ಬಿನ ಗದ್ದೆಗಳಿಂದಾಗಿ ಸಾಮಾನ್ಯವಾಗಿ "ಸಕ್ಕರೆ ನಗರ" ಎಂದು ಕರೆಯಲಾಗುವ ಮಂಡ್ಯವು ತನ್ನ ರಮಣೀಯ ಭೂದೃಶ್ಯಗಳು, ಪ್ರಾಚೀನ ದೇವಾಲಯಗಳು ಮತ್ತು ಐತಿಹಾಸಿಕ ಸ್ಮಾರಕಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ನಿಮ್ಮನ್ನು ಆಹ್ವಾನಿಸುತ್ತದೆ. ಜಿಲ್ಲೆಯ ಹೃತ್ಪೂರ್ವಕ ಆತಿಥ್ಯ ಮತ್ತು ವೈವಿಧ್ಯಮಯ ಆಕರ್ಷಣೆಗಳಲ್ಲಿ ತಲ್ಲೀನರಾಗಿ. ಮಂಡ್ಯ ಜಿಲ್ಲೆಯು ನಿಮಗೆ ಎಂದೂ ಮರೆಯಲಾಗದ ಸಾಹಸಮಯ ಹಾಗು ನೆಮ್ಮದಿಯ ಕ್ಷಣಗಳನ್ನು ಒದಗಿಸಲಿ.' 
    : 'Welcome to Mandya, a vibrant district nestled in the heart of Karnataka, known for its rich cultural heritage, historical significance, and natural beauty. Often referred to as the "Sugar City" due to its extensive sugarcane fields, Mandya invites you to explore its scenic landscapes, ancient temples, and historical monuments. Immerse yourself in the district\'s warm hospitality and diverse attractions, and let Mandya provide you with unforgettable adventures and tranquil moments.';
  const ourMission = isKannada ? 'ನಮ್ಮ ಧ್ಯೇಯ' : 'Our Mission';
  const ourMissionPara = isKannada ? 'ಮಂಡ್ಯ ಪ್ರವಾಸೋದ್ಯಮದಲ್ಲಿ ನಮ್ಮ ಧ್ಯೇಯವೆಂದರೆ ಜಿಲ್ಲೆಯ ವಿಶಿಷ್ಟ ಸಾಂಸ್ಕೃತಿಕ ಪರಂಪರೆ, ನೈಸರ್ಗಿಕ ಸೌಂದರ್ಯ ಮತ್ತು ಐತಿಹಾಸಿಕ ಹೆಗ್ಗುರುತುಗಳನ್ನು ಜಾಗತಿಕ ಪ್ರೇಕ್ಷಕರಿಗೆ ಪ್ರಚಾರ ಮಾಡುವುದು. ಸಮಗ್ರ ಮಾಹಿತಿಯನ್ನು ಒದಗಿಸುವ ಮೂಲಕ, ಸುಸ್ಥಿರ ಪ್ರವಾಸೋದ್ಯಮ ಅಭ್ಯಾಸಗಳನ್ನು ಖಾತ್ರಿಪಡಿಸುವ ಮೂಲಕ ಮತ್ತು ಸಮುದಾಯದ ನಿಶ್ಚಿತಾರ್ಥವನ್ನು ಉತ್ತೇಜಿಸುವ ಮೂಲಕ ಪ್ರಯಾಣದ ಅನುಭವವನ್ನು ಹೆಚ್ಚಿಸಲು ನಾವು ಪ್ರಯತ್ನಿಸುತ್ತೇವೆ. ನಮ್ಮ ಪ್ರಯತ್ನಗಳ ಮೂಲಕ, ನಾವು ಸ್ಥಳೀಯ ಆರ್ಥಿಕತೆಯನ್ನು ಹೆಚ್ಚಿಸುವ ಗುರಿಯನ್ನು ಹೊಂದಿದ್ದೇವೆ, ಮಂಡ್ಯದ ಶ್ರೀಮಂತ ಸಂಪ್ರದಾಯಗಳನ್ನು ಸಂರಕ್ಷಿಸುತ್ತೇವೆ ಮತ್ತು ಪ್ರತಿಯೊಬ್ಬ ಸಂದರ್ಶಕರಿಗೂ ಮರೆಯಲಾಗದ ಅನುಭವಗಳನ್ನು ನೀಡುತ್ತೇವೆ.' 
    : 'Our mission at Mandya Tourism is to promote the district’s unique cultural heritage, natural beauty, and historical landmarks to a global audience. We strive to enhance the travel experience by providing comprehensive information, ensuring sustainable tourism practices, and fostering community engagement. Through our efforts, we aim to boost the local economy, preserve Mandya’s rich traditions, and offer unforgettable experiences to every visitor.';
  const ourVision = isKannada ? 'ನಮ್ಮ ದೃಷ್ಟಿ' : 'Our Vision';
  const ourVisionPara = isKannada ? 'ಇತಿಹಾಸ, ಸಂಸ್ಕೃತಿ ಮತ್ತು ಪ್ರಕೃತಿಯ ಸಾಮರಸ್ಯದ ಮಿಶ್ರಣಕ್ಕೆ ಹೆಸರುವಾಸಿಯಾದ ಮಂಡ್ಯವನ್ನು ಕರ್ನಾಟಕದ ಪ್ರಮುಖ ಪ್ರವಾಸಿ ತಾಣವಾಗಿ ಸ್ಥಾಪಿಸುವುದು ನಮ್ಮ ದೃಷ್ಟಿ. ಸ್ಥಳೀಯ ಸಮುದಾಯ ಮತ್ತು ಪರಿಸರ ಎರಡಕ್ಕೂ ಪ್ರಯೋಜನಕಾರಿಯಾದ ಸುಸ್ಥಿರ ಪ್ರವಾಸೋದ್ಯಮ ಮಾದರಿಯನ್ನು ರಚಿಸಲು ನಾವು ಬಯಸುತ್ತೇವೆ. ನವೀನ ತಂತ್ರಗಳು ಮತ್ತು ಸಹಭಾಗಿತ್ವವನ್ನು ಬಳಸಿಕೊಳ್ಳುವ ಮೂಲಕ, ಮಂಡ್ಯದ ಆಕರ್ಷಣೆಗಳು ಜಾಗತಿಕವಾಗಿ ಗುರುತಿಸಲ್ಪಟ್ಟಿರುವ ಭವಿಷ್ಯವನ್ನು ನಾವು ರೂಪಿಸುತ್ತೇವೆ, ಅದರ ಅನನ್ಯ ಸೌಂದರ್ಯ ಮತ್ತು ಆತಿಥ್ಯವನ್ನು ಅನುಭವಿಸಲು ಪ್ರಪಂಚದ ಎಲ್ಲಾ ಮೂಲೆಗಳಿಂದ ಪ್ರವಾಸಿಗರನ್ನು ಸೆಳೆಯುತ್ತೇವೆ.' 
    : 'Our vision is to establish Mandya as a premier tourist destination in Karnataka, known for its harmonious blend of history, culture, and nature. We aspire to create a sustainable tourism model that benefits both the local community and the environment. By leveraging innovative strategies and partnerships, we envision a future where Mandya’s attractions are globally recognized, drawing visitors from all corners of the world to experience its unique charm and hospitality.';

  const mapSrc = isKannada
    ? "https://www.google.com/maps/embed/v1/place?key=AIzaSyD6Kc3F30Wi9Egb-DCzs3ToaksuFGEJoxs&q=ಮಂಡ್ಯ ಜಿಲ್ಲೆಯ ಪ್ರವಾಸೋದ್ಯಮ ಇಲಾಖೆ"
    : "https://www.google.com/maps/embed/v1/place?key=AIzaSyD6Kc3F30Wi9Egb-DCzs3ToaksuFGEJoxs&q=Tourism Department Mandya Dist";

  return (<>
    {isMobile ?
      <div>
        <Box className={classes.aboutUsImageContainer}>
          <img className={classes.aboutUsImage} src={isKannada ? bannerKannada : banner} alt="aboutUs" />
        </Box>
        
        <Box className={classes.mission} style = {{marginRight : isMobile ? '10px' : '', marginLeft: isMobile ? '10px' : ''}}>
          <Grid container spacing={2}>
            {isMobile ? <></>: <Grid item xs={12} md={6}>
              <img src={Mandya} className={classes.aboutUsImage} style={{paddingLeft : '60px'}} alt="mandya" />
            </Grid>}
            <Grid item xs={12} md={6}>
              <Box>
                <Typography variant="h4" className={classes.underlinedHeading}>{aboutMandya}</Typography>
                <br></br>
                <br></br>
                <Typography variant="body1" className={classes.sectionContent}>{aboutMandyaPara}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.missionVision}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box>
                <Typography variant="h4" className={classes.underlinedHeading}>{ourMission}</Typography>

                <br></br>
                <br></br>

                <Typography variant="body1" className={classes.sectionContent}>{ourMissionPara}</Typography>
              </Box>
            </Grid>
            { isMobile ? <></> : <Grid item xs={12} md={6}>
              <img src={Vision} className={classes.aboutUsImage} style={{paddingRight : '60px'}} alt="vision" />
            </Grid> }
          </Grid>

          <Box className={classes.mission}>
            <Grid container spacing={2}>
              {isMobile ? <></> :<Grid item xs={12} md={6}>
                <img src={Mission} className={classes.aboutUsImage} alt="mission" />
              </Grid> }
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography variant="h4" className={classes.underlinedHeading}>{ourVision}</Typography>
                  <br></br>
                <br></br>
                  <Typography variant="body1" className={classes.sectionContent}>{ourVisionPara}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box className={classes.mission}>
            <Typography variant="h4" className={classes.underlinedHeading}>Department Structure</Typography>
            <br></br>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <img src={Structure} className={classes.aboutUsImage} alt="structure" />
              </Grid>
              <Grid item xs={12} md={6}>
                <img src={Table} className={classes.aboutUsImage} alt="table" />
              </Grid>
            </Grid>
          </Box>


        <br>
        </br>
        <br></br>  
          <Typography variant="h4" className={classes.underlinedHeading}>Department Staff</Typography>
            <br></br>

          <Box className={classes.secondaryFooter}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography variant="h6">Deputy Director</Typography>
                  <Typography variant="subtitle1">Sri Raghavendra H B</Typography>
                  <Typography variant="body2">Contact Number: +91 9448004466</Typography>
                  <Typography variant="body2">Email Id: ddmandyadtd@gmail.com</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography variant="h6">Project Officer</Typography>
                  <Typography variant="subtitle1">Sri S M Sharanappa</Typography>
                  <Typography variant="body2">Contact Number: +91 9632561622</Typography>
                  <Typography variant="body2">Email Id: projectofficemandyadtd@gmail.com</Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </div>
      :
      <div>
      <Box className={classes.aboutUsImageContainer} style={{marginRight: '120px '}}>
        <img className={classes.aboutUsImage} src={isKannada? bannerKannada : banner} alt="aboutUs" />
      </Box>
      
      <Box className={classes.mission} style={{maxHeight: '400px'}}>
          <img src={Mandya} style={{ marginLeft: '120px', minWidth: '500px', borderRadius: '10px'}} alt='mission' />
          <div style={{ marginLeft: '100px', marginRight:'100px', minWidth: '550px', maxWidth: '750px' }}>
            <Typography variant="h4" className={classes.underlinedHeading}>{aboutMandya}</Typography>
            <br></br>
            <br></br>
            <Typography variant="body1" className={classes.sectionContent}>{aboutMandyaPara}</Typography>
          </div>
        </Box>

      <Box className={classes.missionVision}>
        <Box className={classes.vision} style={{maxHeight: '350px'}}>
          <div style={{maxWidth: '650px'}}>
            <Typography variant="h4" className={classes.underlinedHeading}>{ourMission}</Typography>
            <br></br>
            <br></br>
            <Typography variant="body1" className={classes.sectionContent}>{ourMissionPara}</Typography>
          </div>
          <img style={{ marginLeft: '40px', maxWidth: '650px' , borderRadius: '10px'}} src={Vision} alt='vision' />
        </Box>

        <Box className={classes.mission} style={{maxHeight: '400px'}}>
          <img src={Mission} style={{ minWidth: '500px', borderRadius: '10px'}} alt='mission' />
          <div style={{ paddingLeft: '120px', minWidth: '550px', maxWidth: '750px', marginRight:'100px' }}>
            <Typography variant="h4" className={classes.underlinedHeading}>{ourVision}</Typography>
            <br></br>
            <br></br>
            <Typography variant="body1" className={classes.sectionContent}>{ourVisionPara}</Typography>
          </div>
        </Box>


        <br>
        </br>
        <br></br>


        <h3 style={{fontSize:'25px'}} className={classes.underlinedHeading}>Department Structure</h3>
         <Box className={classes.mission} style={{maxHeight: '400px', width: '400px', marginTop: '-100px'}}>          
          <img src={Structure} style={{ minWidth: '500px'}} alt='mission' />
          <img src={Table} style={{ minWidth: '500px', marginLeft: '150px'}} alt='mission' />
        </Box>
        <br></br>
        
        <h3 style={{fontSize:'25px'}} className={classes.underlinedHeading}>Department Staff</h3>
        <Box className={classes.secondaryFooter} style={{ maxWidth: '800px', margin: 'auto' }}>
          <Grid container spacing={2} style={{ marginBottom: '20px' }}>
            <Grid item xs={12} md={7}>
              <Box style={{ padding: '10px', borderRadius: '10px', background: '#f0f0f0' }}>
                <Typography variant="h6">Deputy Director</Typography>
                <Typography variant="subtitle1">Sri Raghavendra H B</Typography>
                <Typography variant="body2">Contact Number: +91 9448004466</Typography>
                <Typography variant="body2">E-Mail ID: ddmandyatourism@gmail.com</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              {/* Second item goes here */}
              <Box style={{ padding: '10px', borderRadius: '10px', background: '#f0f0f0', width: '360px', gap:'100px' }}>
                <Typography variant="h6">Tourist Officer</Typography>
                <Typography variant="subtitle1">Sri Kiran G C</Typography>
                <Typography variant="body2">Contact Number: +91 9591213224</Typography>
                <Typography variant="body2">E-Mail ID: ddmandyatourism@gmail.com</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <br></br>
        <Box className={classes.iframeContainer} style={{marginLeft: '-132px'}}>
          <iframe
            title="Google Maps"
            width="100%"
            height="450"
            style={{ border: 0 }}
            loading="lazy"
            allowFullScreen
            src={mapSrc}
          ></iframe>
        </Box>
      </Box>
     

    </div> }</>);
}

export default AboutUs;
