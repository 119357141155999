import data from '../../Data/placesData.json';
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate } from 'react-router-dom';
import { Box, Button, Modal, useTheme, useMediaQuery } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  gallery: {
    marginLeft: "50px",
    marginRight: "50px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
      marginRight: "10px",
    },
  },
  galleryBlocks: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    marginTop: "20px",
  },
  images: {
    margin: "10px",
    maxWidth: "calc(33.33% - 20px)",
    height: "auto",
    borderRadius: "8px",
    cursor: "pointer",
    objectFit: "cover",
    transition: "transform 0.3s ease-in-out",
    "&:hover": {
      transform: "scale(1.05)",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "calc(50% - 20px)",
      margin: "10px",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "calc(100% - 20px)",
      margin: "5px",
    },
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "left",
    marginTop: "20px",
    gap: "20px",
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  button: {
    margin: "0 10px",
    borderRadius: "20px",
    textTransform: "none",
    padding: "10px 20px",
    fontSize: "14px",
    fontWeight: "600",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    color: 'black',
    transition: "background-color 0.3s, color 0.3s, transform 0.3s",
    "&:hover": {
      backgroundColor: theme.palette.primary.black,
      color: theme.palette.common.black,
      transform: "scale(1.05)",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: 0,
  },
  modalContent: {
    maxWidth: "90vw",
    maxHeight: "90vh",
    objectFit: "contain",
    boxShadow: theme.shadows[5],
    borderRadius: "8px",
    position: "relative", // Added to position the caption
  },
  caption: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "white",
    textAlign: "center",
    padding: "10px",
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
  },
  underlinedHeading: {
    fontFamily: 'Montserrat, sans-serif',
    marginBottom: '10px',
    fontSize: '28px',
    color: 'black',
    position: 'relative',
    textDecoration: 'none',
    display: 'inline-block',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: -2,
      width: '60%',
      borderBottom: '4px solid #FFD700 !important',
    },
  },
}));

const Gallery = ({ isKannada }) => {
  const classes = useStyles();
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [openModal, setOpenModal] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [modalPlaceName, setModalPlaceName] = useState(""); // State for place name
  const [modalId, setModalId] = useState("");
  const [loadedImages, setLoadedImages] = useState(9);
  const gallery = isKannada ? 'ಗ್ಯಾಲರಿ' : 'Gallery';
  const navigate = useNavigate();

  // Flatten images and associate with place names
  const images = data.flatMap((item) => item.images.map(image => ({ src: image, placeName: isKannada ? item.nameKannada : item.name, id: item.id })));

  // Extract categories
  const categories = [...new Set(data.map((item) => item.circuit || item.taluk))];

  // Filter images based on selected category
  const filteredImages = selectedCategory === "all"
    ? images.slice(0, loadedImages)
    : images.filter((image) =>
        data.some((item) =>
          (item.circuit === selectedCategory || item.taluk === selectedCategory) &&
          item.images.includes(image.src)
        )
      ).slice(0, loadedImages);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setLoadedImages(10);
  };

  const handleLoadMore = () => {
    setLoadedImages((prevCount) => prevCount + 10);
  };

  const handleImageClick = (imageSrc, placeName, id) => {
    setModalImage(imageSrc);
    setModalPlaceName(placeName);
    setModalId(id);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalImage(null);
    setModalPlaceName("");
    setModalId("");
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const exploreBtn = isKannada ? "ಅನ್ವೇಷಿಸಿ" : "Explore";

  return (
    <Box className={classes.gallery} style={{ marginTop: '50px' }}>
      <h2 className={classes.underlinedHeading}>{gallery}</h2>
      <div className={classes.buttonGroup} style={{ gap: isMobile ? '10px' : '20px' }}>
        {[...categories, "all"].map((category) => (
          <Button
            key={category}
            className={classes.button}
            variant={selectedCategory === category ? "contained" : "outlined"}
            style={{
              fontSize: isMobile ? '10px' : '14px',
              borderColor: 'black',
              color: 'black',
              backgroundColor: selectedCategory === category ? '#f1981a' : '#f0f0f0',
            }}
            onClick={() => handleCategoryChange(category)}
          >
            <b>{category === "all" ? "All" : category}</b>
          </Button>
        ))}
      </div>
      <Box className={classes.galleryBlocks}>
        {filteredImages.map((imageObj, index) => (
          <img
            key={index}
            className={classes.images}
            src={imageObj.src}
            alt={`Gallery ${index + 1}`}
            onClick={() => handleImageClick(imageObj.src, imageObj.placeName, imageObj.id)}
          />
        ))}
      </Box>

      {/* Load More Button */}
      <div style={{ alignItems: 'center', width: '100%' }}>
        {loadedImages < images.length && (
          <Button
            className={classes.button}
            variant="outlined"
            color="primary"
            style={{
              fontSize: isMobile ? '10px' : '14px',
              borderColor: 'black',
              color: 'black',
              backgroundColor: '#f1981a',
            }}
            onClick={handleLoadMore}
          >
            <b>Load More</b>
          </Button>
        )}
      </div>

      {/* Modal for displaying full-screen image */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        className={classes.modal}
        aria-labelledby="image-modal"
        aria-describedby="modal-to-display-fullscreen-image"
      >
        <Box className={classes.modalContent}>
          <img
            src={modalImage}
            alt="Full Screen"
            style={{ maxWidth: "100%", maxHeight: "80vh" }}
          />
          {modalPlaceName && (
            <Box className={classes.caption}>
              <h3 style={{color: "white"}}>{modalPlaceName}</h3>
              <Button
                key={"1"}
                className={classes.button}
                variant={"contained"}
                style={{
                  fontSize: isMobile ? '10px' : '14px',
                  borderColor: 'black',
                  color: 'black',
                  backgroundColor: '#f1981a',
                }}
                onClick={() => navigate(`/destination/detail?search=${modalId}`)}
              >
                <b>{exploreBtn}</b>
              </Button>
            </Box>
          )}
          
        </Box>
      </Modal>
      
      <br></br>
      <br></br>
    </Box>
  );
}

export default Gallery;
