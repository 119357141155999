import React from 'react';
import './ArtCard.css';

const ArtCard = ({ art, isKannada, onClick }) => {
  const truncateDescription = (description) => {
    if (!description) return '';
    const maxLength = 10; // Maximum number of words
    const ellipsis = '...';
    const words = description.split(' ');

    if (words.length > maxLength) {
      return words.slice(0, maxLength).join(' ') + ellipsis;
    }
    return description;
  };

  return (
    <div className="art-card" onClick={() => onClick(art)}>
      <div className="art-image-container">
        <img className="art-image" src={art.image} alt={isKannada ? art.name : art.name} />
        <div className="overlay">
          <h2 className="art-title" style= {{color: 'white' , fontFamily: 'Montserrat, sans-serif'}}>{isKannada ? art.name : art.name}</h2>
          <p className="art-description" style= {{color: '#f1f1f1', fontFamily: 'Montserrat, sans-serif'}}>{truncateDescription(isKannada ? art.description : art.description)}</p>
        </div>
      </div>
    </div>
  );
};

export default ArtCard;
