import React, { useState, useEffect } from 'react';
import { Box, Button, useTheme, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FestivalImage1 from '../../Assets/homeImages/home/SriRangapattanaDasara.jpg';
import { festivalHeading, festivalSubHeading, festivalsDataArray, festivalParaKannada, festivalsKannada } from './festivalsData';
import '../css/home.css';
import banner from '../../Assets/Banners/Festivals.jpg';
import bannerKannada from '../../Assets/Banners/FestivalsKannada.jpg';

const useStyles = makeStyles((theme) => ({
  festivalBlock: {
    marginLeft: '0px',
    marginRight: '0px',
  },
  festivalImage: {
    marginTop: '10px',
    width: '100%',
    height: '75vh',
    opacity: '0px',
    maxWidth: '100%',
  },
  dateBlock: {
    backgroundColor: '#EAEAF1',
    width: '137px',
    paddingLeft: '20px',
    paddingRight: '20px',
    maxHeight: '150px',
  },
  aboutUsImageContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  aboutUsImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  festivalDescription: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '20px',
    fontFamily: 'Montserrat, sans-serif',
    maxWidth: '600px',
  },
  festivalParagraph: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '24px',
  },
  festivalCard: {
    display: 'flex',
    flexDirection: 'column',  // Changed to column for mobile layout
    marginTop: '20px',
    alignItems: 'center',
    border: '1px solid #ccc',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.02)',
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',  // Revert to row for larger screens
      alignItems: 'center',
    },
  },
  festivalImageWrapper: {
    width: '100%',  // Adjusted width for mobile
    height: 'auto',  // Ensures image height adjusts proportionally
    marginBottom: '10px',  // Added margin for spacing
    [theme.breakpoints.up('sm')]: {
      height: '225px',
      width: '450px',
      marginLeft: '50px',
    },
  },
  locateOnMapButton: {
    marginTop: '10px',
    backgroundColor: '#f5b963',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#e0a344',
    },
  },
  underlinedHeading: {
    fontFamily: 'Montserrat, sans-serif',
    marginBottom: '10px',
    fontSize: '28px',
    color: 'black',
    position: 'relative',
    textDecoration: 'none',
    display: 'inline-block',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: -2,
      width: '60%',
      borderBottom: '4px solid #FFD700 !important',
    },
  },
}));

function Festivals({ isKannada }) {
  const classes = useStyles();
  
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const images = [
    FestivalImage1,
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div className={classes.festivalBlock}>
      <Box className={classes.aboutUsImageContainer}>
        <img className={classes.aboutUsImage} src={isKannada ? bannerKannada : banner} alt="aboutUs" />
      </Box>

      <div style={{ maxWidth: '100%', fontFamily: 'Montserrat, sans-serif', marginLeft: isMobile ? '10px' : '60px', marginRight: isMobile ? '10px' : '60px' }}>
        <h2 className={classes.underlinedHeading}>{isKannada ? festivalsKannada : festivalHeading}</h2>
        <p style={{ maxWidth: '100%' }}>{isKannada ? festivalParaKannada : festivalSubHeading}</p>
      </div>

      <div style={{ maxWidth: '100%', marginBottom: '20px', marginLeft: isMobile ? '10px' : '60px', marginRight: isMobile ? '10px' : '60px' }}>
        {festivalsDataArray.map((festival, index) => (
          <Box key={index} className={classes.festivalCard}>
            <img style={{ borderRadius: '8px', maxWidth: '100%', objectFit: 'cover' }} className={classes.festivalImageWrapper} src={festival.image} alt="festivalImage" />
            <div className={classes.festivalDescription}>
              <h3 style={{ marginTop: '0px' }}>{isKannada ? festival.nameKannada : festival.name}</h3>
              <p className={classes.festivalParagraph}>{isKannada ? festival.descriptionKannada : festival.description}</p>
              <Button
                className={classes.locateOnMapButton}
                variant="contained"
                onClick={() => window.open(festival.mapUrl, '_blank')}
              >
                Locate on Map
              </Button>
            </div>
          </Box>
        ))}
      </div>
    </div>
  );
}

export default Festivals;
