import React from "react";
import { Box, Grid, Typography, Link as MuiLink } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import Logo from '../Assets/final/logo_white_final_kannada.png';
import logo_english from '../Assets/final/logo_white_final_english.png';
import Logokarnataka from '../Assets/Logos/klb.png';
import download from '../Assets/Mandya District Brochure.pdf';

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    backgroundColor: 'black !important',
    padding: '50px 20px',
    color: 'white',
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  klogo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  klogoImg: {
    width: '50%',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  logoImg: {
    width: '120px',
    height: '90px',
    marginBottom: '10px',
  },
  column: {
    padding: '10px 20px',
    textAlign: 'center',
  },
  link: {
    textDecoration: "none",
    color: "white",
    // borderBottom: "1px solid transparent",
    fontSize: '15px',
    fontWeight: '400',
    lineHeight: '23px',
    display: 'block',
    margin: '5px 0',
  },
  iframeContainer: {
    marginBottom: '40px',
  },
  bottomFooter: {
    marginTop: '20px',
    paddingTop: '20px',
    borderTop: '1px solid white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '14px',
  },
  bottomFooterColumn: {
    textAlign: 'center',
    flex: 1,
  },
  bottomFooterLink: {
    color: 'lightblue',
  },
}));

function Footer({ isKannada }) {
  const classes = useStyles();
  const logo = isKannada ? Logo : Logo;

  const handleDownload = () => {
    // Logic to prepare the file URL
    const fileUrl = download;
    // Use an anchor tag to trigger the download
    const anchor = document.createElement('a');
    anchor.href = fileUrl;
    anchor.download = 'Mandya_District_Brochure.pdf';
    anchor.click();
  };

  return (
    <div>
      

      <Box className={classes.footerContainer}>
        <Grid container spacing={4}>
          {/* First Column */}
          <Grid item xs={12} md={3} className={classes.column}>
            <div className={classes.logo}>
              <img src={logo} alt="logo" className={classes.logoImg} />
            </div>
            <Typography variant="h6" gutterBottom>
              {isKannada ? 'ಮಂಡ್ಯ ಪ್ರವಾಸೋದ್ಯಮ ಇಲಾಖೆ' : 'Mandya Tourism Department'}
            </Typography>
            <Typography variant="body2">
              {isKannada ? 'ಪಿಇಎಸ್ ಕಾಲೇಜು ಆವರಣ' : 'PES College Campus'}
            </Typography>
            <Typography variant="body2">
              {isKannada ? 'ಮಂಡ್ಯ, ಕರ್ನಾಟಕ 571401' : 'Mandya, Karnataka 571401'}
            </Typography>
            <Typography variant="body2">
              {isKannada ? 'ದೂರವಾಣಿ: 08232-23877' : 'Phone: 08232-23877'}
            </Typography>
            <Typography variant="body2">
              {isKannada ? 'ಇಮೇಲ್: info@mandyatourism.com' : 'Email: info@mandyatourism.com'}
            </Typography>
            <MuiLink
              href="https://maps.app.goo.gl/JLWJ5ZUYvsdc8nkN7"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              {isKannada ? 'ಗೂಗಲ್ ನಕ್ಷೆ: ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ' : 'Google Map: Click Here'}
            </MuiLink>
          </Grid>

          {/* Second Column */}
          <Grid item xs={12} md={3} className={classes.column}>
            <Typography variant="h6" gutterBottom>
              {isKannada ? 'ತ್ವರಿತ ಲಿಂಕ್‌ಗಳು' : 'Quick Links'}
            </Typography>
            <Link to="/" className={classes.link}>
              {isKannada ? 'ಮುಖಪುಟ' : 'HOME'}
            </Link>
            <Link to="/destination" className={classes.link}>
              {isKannada ? 'ಆಕರ್ಷಣೆಗಳು' : 'DESTINATIONS'}
            </Link>
            <Link to="/festivals" className={classes.link}>
              {isKannada ? 'ಹಬ್ಬಗಳು' : 'FESTIVALS'}
            </Link>
            <Link to="/contacts" className={classes.link}>
              {isKannada ? 'ಸಂಪರ್ಕಿಸಿ' : 'CONTACT US'}
            </Link>
            <Link to="/travelGuide" className={classes.link}>
              {isKannada ? 'ಪ್ರಯಾಣ ಮಾರ್ಗದರ್ಶಿ' : 'TRAVEL GUIDE'}
            </Link>
            <Link to="/gallery" className={classes.link}>
              {isKannada ? 'ಗ್ಯಾಲರಿ' : 'GALLERY'}
            </Link>
            <Link to="/aboutUs" className={classes.link}>
              {isKannada ? 'ನಮ್ಮ ಬಗ್ಗೆ' : 'ABOUT US'}
            </Link>
          </Grid>

          {/* Third Column */}
          <Grid item xs={12} md={3} className={classes.column}>
            <Typography variant="h6" gutterBottom>
              {isKannada ? 'ಬಾಹ್ಯ ಲಿಂಕ್‌ಗಳು' : 'External Links'}
            </Typography>
            <MuiLink
              href="https://kttf.karnatakatourism.org/"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              * KTTF Registration For Homestay, Hotel/Resort, Restaurant, Travel Agents, Amusement Park, Caravan Tourism And Tourist Transport Operator
            </MuiLink>
            <MuiLink
              href="https://karnatakatourism.org/department/tourism-policy/"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              * Karnataka Tourism Policy Guidelines And Applications For Subsidy
            </MuiLink>
            <MuiLink
              href="https://www.incredibleindia.org/content/incredible-india-v2/en.html"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              * Incredible India
            </MuiLink>
            <MuiLink
              onClick={handleDownload}
              className={classes.link}
              style={{ cursor: "pointer" }} // Ensure cursor changes on hover for download link
            >
              * Mandya District Brochure
            </MuiLink>
          </Grid>

          {/* Fourth Column */}
          <Grid item xs={12} md={3} className={classes.column}>
            <div className={classes.klogo}>
              <img src={Logokarnataka} alt="logo" className={classes.klogoImg} />
            </div>
            <Typography variant="h6" gutterBottom>
              {isKannada ? 'ಕರ್ನಾಟಕ ಪ್ರವಾಸೋದ್ಯಮ ಇಲಾಖೆ' : 'Karnataka Tourism Department'}
            </Typography>
            <Typography variant="body2">
              {isKannada ? '4ನೇ ಮಹಡಿ/ಮಟ್ಟ,' : '4th Floor/Level,'}
            </Typography>
            <Typography variant="body2">
              {isKannada ? 'ಸಂಖ್ಯೆ 3, ರಾಯಭಾರಿ ಐಕಾನ್, ಇನ್ಫ್ಯಾನ್ಟ್ರಿ ರಸ್ತೆ,' : 'No 3, Embassy Icon, Infantry Road,'}
            </Typography>
            <Typography variant="body2">
              {isKannada ? 'ಬೆಂಗಳೂರು-560001' : 'Bengaluru-560001'}
            </Typography>
            <Typography variant="body2">
              {isKannada ? 'ದೂರವಾಣಿ: +91-80-2235-2828' : 'Tel: +91-80-2235-2828'}
            </Typography>
            <Typography variant="body2">
              {isKannada ? 'ಇಮೇಲ್: feedback@karnatakatourism.org' : 'Email: feedback@karnatakatourism.org'}
            </Typography>
            <MuiLink
              href="https://maps.app.goo.gl/1ohrqTg9BvVTFYNQA"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              {isKannada ? 'ಗೂಗಲ್ ನಕ್ಷೆ: ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ' : 'Google Map: Click Here'}
            </MuiLink>
          </Grid>
        </Grid>
        <Box className={classes.bottomFooter}>
          <Typography variant="body2" className={classes.bottomFooterColumn}>
            {isKannada ? '© 2024 ಮಂಡ್ಯ ಪ್ರವಾಸೋದ್ಯಮ ಇಲಾಖೆ ಎಲ್ಲಾ ಹಕ್ಕುಗಳನ್ನು ಕಾಯ್ದಿರಿಸಲಾಗಿದೆ.' : '© 2024 Mandya Tourism Department All Rights Reserved.'}
          </Typography>
          <Typography variant="body2" className={classes.bottomFooterColumn}>
            <span className={classes.poweredByText}>Powered by </span>
            <MuiLink href="https://satoir.com" target="_blank" rel="noopener noreferrer" className={classes.bottomFooterLink}>
              SATOIR
            </MuiLink>
          </Typography>
          <Typography variant="body2" className={classes.bottomFooterColumn}>
            {isKannada ? 'ನಿಮ್ಮ ಕಾಳಜಿಗಳಿಗೆ ಸಂಬಂಧಿಸಿದಂತೆ, ದಯವಿಟ್ಟು ನಮಗೆ ಇಮೇಲ್ ಮಾಡಿ.' : 'Disclaimer: For concerns related to content posted, kindly mail to us.'}
          </Typography>
        </Box>
      </Box>
    </div>
  );
}

export default Footer;
