// src/TravelGuide.js
import React from 'react';
import data from './data';
import { makeStyles } from "@mui/styles";
import './TravelGuide.css';
import { useTheme, useMediaQuery } from "@mui/material"; // Import the new CSS file

const useStyles = makeStyles((theme) => ({
  underlinedHeading: {
    fontFamily: 'Montserrat, sans-serif',
    marginBottom: '10px',
    fontSize : '28px',
    color: 'black', // Set font color to black
    position: 'relative', // Required for positioning pseudo-element
    textDecoration: 'none', // Remove default underline
    display: 'inline-block', // Ensure the pseudo-element respects the text width
    '&::after': {
      content: '""', // Required content for pseudo-element
      position: 'absolute',
      left: 0,
      bottom: 2,
      width: '60%', // Set width of the underline to 100% of the text width
      borderBottom: '4px solid #FFD700 !important', // Define double underline
    },
  },
}));

const TravelGuide = ({ isKannada }) => {
  const language = isKannada ? 'kn' : 'en';
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const paragraph = isKannada ? 'ಮಂಡ್ಯದ ನಿಜವಾದ ಸೊಬಗನ್ನು ನೀವು ಆನಂದದಿಂದ ಸವಿಯಲು, ನಿಮ್ಮ ಆದ್ಯತೆಗೆ ಅನುಗುಣವಾಗಿ ತಂಗಲು, ಊಟ ಮಾಡಲು ಮತ್ತು ವಿಶ್ರಾಂತಿ ಪಡೆಯಲು ಉತ್ತಮ ಸ್ಥಳಗಳನ್ನು ಇಲ್ಲಿ ಅನ್ವೇಷಿಸಿ. ಮಂಡ್ಯಾದ ಮನೋಹರ ಗಮ್ಯಸ್ಥಾನದಲ್ಲಿಯ ನಿಮ್ಮ ಪ್ರಯಾಣದ ಅನುಭವವನ್ನು ಹೆಚ್ಚಿಸಲು ನಾವು ನಿಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತಾ, ನಿಮ್ಮ ಪಯಣದುದ್ದಕ್ಕೂ ಮರೆಯಲಾಗದ ನೆನಪುಗಳನ್ನು ಸೃಷ್ಟಿಸುತ್ತೇವೆ.' 
  : 'Explore Mandya effortlessly. Discover the best places to stay, dine, and unwind, tailored to your preference preference, ensuring you don\'t miss out on the true essence of Mandya. Let us help you navigate your journey and make the most of your visit to this enchanting destination, creating unforgettable memories along the way.';

  return (
    <div className="travel-guide" style={{fontFamily: 'Montserrat, sans-serif', backgroundColor: '#f1f1f1', paddingLeft: isMobile ? '10px' : '60px', paddingRight: isMobile ? '10px' :  '60px'}}>
      <h1 className={classes.underlinedHeading} style={{fontFamily: 'Montserrat, sans-serif'}}>{isKannada ? 'ಪ್ರಯಾಣ ಮಾರ್ಗದರ್ಶಿ' : 'Travel Guide'}</h1>
      <p style={{fontFamily: 'Montserrat, sans-serif'}}>{paragraph}</p>
      <section>
        <h2 className={classes.underlinedHeading} style={{fontFamily: 'Montserrat, sans-serif'}}>{isKannada ? 'ಟೂರ್ಸ್ ಅಂಡ್ ಟ್ರಾವೆಲ್ ಏಜೆನ್ಸಿಗಳು' : 'Tours and Travels Agencies'}</h2>
        <div className="cards-container">
          {data[language].toursAndTravels.map((agency, index) => (
            <div key={index} className="card">
              <h3 style={{fontFamily: 'Montserrat, sans-serif'}}>{agency.name}</h3>
              <p style={{fontFamily: 'Montserrat, sans-serif'}}>{agency.address}</p>
              <p style={{fontFamily: 'Montserrat, sans-serif'}}>{agency.phone}</p>
              <p style={{fontFamily: 'Montserrat, sans-serif'}}>{agency.email}</p>
            </div>
          ))}
        </div>
      </section>
    
      <section>
        <h2 className={classes.underlinedHeading}>{isKannada ? 'ಹೋಮ್ಸ್ಟೇ ವಿವರಗಳು' : 'Homestay Details'}</h2>
        <div className="cards-container">
          {data[language].homestays.map((homestay, index) => (
            <div key={index} className="card">
              <h3>{homestay.name}</h3>
              <p>{homestay.address}</p>
              <p>{homestay.phone}</p>
              <p>{homestay.email}</p>
              {/* {homestay.website && <a href={homestay.website} target="_blank" rel="noopener noreferrer">{homestay.website}</a>} */}
            </div>
          ))}
        </div>
      </section>
      
      <section>
        <h2 className={classes.underlinedHeading}>{isKannada ? 'ಹೋಟೆಲ್ ಮತ್ತು ರೆಸಾರ್ಟ್ ವಿವರಗಳು' : 'Hotel and Resorts Details'}</h2>
        <div className="cards-container">
          {data[language].hotelsAndResorts.map((hotel, index) => (
            <div key={index} className="card">
              <h3>{hotel.name}</h3>
              <p>{hotel.address}</p>
              <p>{hotel.phone}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default TravelGuide;
