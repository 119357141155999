import React, { useState, useEffect } from "react";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from 'react-router-dom';
import { placesData as placeData } from "./destinationData";
import axios from 'axios';
import PropTypes from 'prop-types';
import { Component } from './Component'; // Adjust the import path as necessary

const useStyles = makeStyles((theme) => ({
  explorePages: {
    marginLeft: '50px',
    marginTop: '60px'
  },
  smallHeading: {
    fontFamily: 'Urbanist',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '16px',
    textAlign: 'left',
    margin: 0, // Remove default margin
  },
  popularDestHeading: {
    fontFamily: 'Urbanist',
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '24px',
    height: '50px',
    textAlign: 'left',
    margin: 0, // Remove default margin
  },
  popularDestBlock: {
    background: '#2B3030',
    color: '#E0F2EB',
    maxWidth: '367px',
    padding: '20px',
    marginBottom: '20px', // Adjust margin bottom
  },
  underlinedHeading: {
    fontFamily: 'Montserrat, sans-serif',
    marginBottom: '10px',
    fontSize : '28px',
    color: 'black', // Set font color to black
    position: 'relative', // Required for positioning pseudo-element
    textDecoration: 'none', // Remove default underline
    display: 'inline-block', // Ensure the pseudo-element respects the text width
    '&::after': {
      content: '""', // Required content for pseudo-element
      position: 'absolute',
      left: 0,
      bottom: -2,
      width: '60%', // Set width of the underline to 100% of the text width
      borderBottom: '4px solid #FFD700 !important', // Define double underline
    },
  },
}));

const DestinationCategory = ({ isKannada }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const [searchParam, setSearchParam] = useState(searchParams.get('search')); // Initialize searchParam state with parsed parameter

  const [placesData, setPlacesData] = useState([]);

  useEffect(() => {
    // Parse search parameter from URL
    setSearchParam(searchParams.get('search'));
    
  }, [searchParams]); // Depend on searchParams to trigger updates when URL changes

  useEffect(() => {
    if (!searchParam) return; // Prevent fetching data if searchParam is not set

    // Determine whether the search parameter is a taluk or circuit
    const isCircuit = ['natural', 'spiritual', 'historical', 'other'].includes(searchParam.toLowerCase());

    // Construct API URL based on the type of search parameter
    const API_BASE_URL = isCircuit
      ? `http://ec2-3-105-71-196.ap-southeast-2.compute.amazonaws.com:8080/api/Place/circuit/${searchParam}`
      : `http://ec2-3-105-71-196.ap-southeast-2.compute.amazonaws.com:8080/api/Place/taluk/${searchParam}`;
    
    // Fetch data for the specified category
    const fetchData = async () => {
      try {
        const response = await axios.get(API_BASE_URL);
        setPlacesData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        // Fallback to local data if API fails
        console.log('Fetching local data instead:', placeData);
        const places = isCircuit ? placeData.filter(place => place.circuit === searchParam) :
        placeData.filter(place => place.taluk === searchParam);
        console.log('Filtered local data:', places);
        setPlacesData(places);
      }
    };

    fetchData();
  }, [searchParam]); // Depend on searchParam to trigger data fetching

  const exploreDest = isKannada ? 'ಆಕರ್ಷಣೆಗಳನ್ನು ಅನ್ವೇಷಿಸಿ' : 'Explore Destinations'

  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div>
      <Box className={classes.explorePages} style={{marginLeft: isMobile ? '10px' : '50px', marginRight: isMobile ? '10px' : '50px'}}>
      <h2 className={classes.underlinedHeading}>{exploreDest}</h2>
      <br></br>
      <br></br>
        <Box style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(367px, 1fr))', gap: '20px', marginBottom: '10px' }}>
          {placesData.map((place, index) => (
            <Box key={index}>
              <Component
                image={place.images && place.images.length > 0 ? place.images[0] : ''}
                text={isKannada ? place.nameKannada : place.name}
                text1={isKannada ? place.descriptionKannada : place.description}
                onClick={() => navigate(`/destination/detail?search=${place.id}`)} // Navigate to detail page
              />
            </Box>
          ))}
        </Box>
      </Box>
    </div>
  );
};

// PropTypes for type checking
DestinationCategory.propTypes = {
  placesData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ),
};

export default DestinationCategory;
