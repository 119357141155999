import React from "react";
import {
    AppBar,
    Toolbar,
    CssBaseline,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Divider,
    Switch,
    FormControlLabel,
    Typography,
    useTheme,
    useMediaQuery,
    Menu,
    MenuItem,
} from "@mui/material";
import {Popover} from "@material-ui/core";
import { makeStyles } from "@mui/styles";
import { Link, useLocation } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import FinalLogoEnglish from '../Assets/final/logo_final_english.png';
import FinalLogoKannada from '../Assets/final/logo_final_kannada.png';
import KarnatakaTourismLogo from '../Assets/Logos/karnataka_logo.svg';

const useStyles = makeStyles((theme) => ({
    navlinks: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            display: "none",
        },
    },
    navBar: {
        backgroundColor: "#F1F1F1 !important",
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1100,
        fontFamily: 'Montserrat, sans-serif',
    },
    toolbar: {
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    logo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    link: {
        textDecoration: "none",
        color: "#000000",
        margin: '0 20px',
        borderBottom: "1px solid transparent",
        fontSize: '15px',
        fontWeight: '600',
        lineHeight: '23px',
        textAlign: 'left',
        fontFamily: 'Montserrat, sans-serif',
        transition: 'color 0.3s ease, borderBottom 0.3s ease',
        '&:hover': {
            color: '#f5b963',
            borderBottom: "1px solid #f5b963",
        }
    },
    activeLink: {
        fontWeight: '700',
        color: 'black', // Yellow color for active link
        borderBottom: "3px solid #FFD700 !important", // Yellow border for active link
    },
    languageSwitch: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '20px',
    },
    languageLabel: {
        fontFamily: 'Montserrat, sans-serif',
        marginLeft: '10px',
        fontWeight: 'bold',
    },
    mobileNavLinks: {
        textDecoration: 'none',
        color: '#000000',
        fontFamily: 'Montserrat, sans-serif',
        transition: 'color 0.3s ease',
        '&:hover': {
            color: '#FFD700',
        }
    },
    dropdownMenu: {
        marginTop: '8px',
    },
}));

function Navbar({ isKannada, toggleLanguage }) {
    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isMenuOpen, setMenuOpen] = React.useState(false); // State to track if menu is open
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const location = useLocation();

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleDestinationHover = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleDestinationClose = () => {
        setAnchorEl(null);
    };

    const handleMenuOpen = (event) => {
        if (!isMobile) {
            setAnchorEl(event.currentTarget);
        }
        setMenuOpen(true); // Set menu open when mouse over or click (for mobile)
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setMenuOpen(false); // Set menu closed when mouse out
    };

    const handleMenuSelect = () => {
        setMenuOpen(false); // Close menu when an item is selected
        setAnchorEl(null); // Also close the anchor element
    };

    const handleMenuMouseLeave = () => {
        setAnchorEl(null);
        setMenuOpen(false); // Close menu when mouse leaves the dropdown area
    };

    const open = Boolean(anchorEl);

    const home = isKannada ? 'ಮುಖಪುಟ' : 'Home';
    const destination = isKannada ? 'ಆಕರ್ಷಣೆಗಳು' : 'Destinations';
    const artsAndCuisine = isKannada ? 'ಕಲೆ ಮತ್ತು ಪಾಕಪದ್ಧತಿ' : 'Art and Cuisine';
    const festivals = isKannada ? 'ಹಬ್ಬಗಳು' : 'Festivals';
    const gallery = isKannada ? 'ಗ್ಯಾಲರಿ' : 'Gallery';
    const aboutUs = isKannada ? 'ನಮ್ಮ ಬಗ್ಗೆ' : 'About us';
    const kannada = 'ಕನ್ನಡ';
    const spiritual = isKannada ? 'ಆಧ್ಯಾತ್ಮಿಕ ತಾಣಗಳು' : 'Spiritual Destinations';
    const natural = isKannada ? 'ಪ್ರಕೃತಿ ಮತ್ತು ವನ್ಯಜೀವಿ' : 'Natural Destination';
    const historical = isKannada ? 'ಐತಿಹಾಸಿಕ ತಾಣಗಳು' : 'Historical Destinations';
    const other = isKannada ? 'ಇತರೆ ತಾಣಗಳು' : 'Other Destinations';

    return (
        <AppBar position="fixed" className={classes.navBar}>
            <CssBaseline />
            <Toolbar className={classes.toolbar}>
                {isMobile ? (
                    <>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="end"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon style={{ color: 'black' }} /> {/* Set menu icon color to black */}
                        </IconButton>
                        <div className={classes.logo} style={{marginLeft:'15px'}}>
                            <a href="/" rel="noopener noreferrer">
                                <img style={{ height: '100px', width: '120px', objectFit: 'cover'}} src={isKannada? FinalLogoKannada : FinalLogoEnglish} alt="Cultural Tourism" />
                            </a>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={classes.logo}>
                        <a href="/" rel="noopener noreferrer">
                            <img style={{ height: '100px', width: '120px', objectFit: 'cover'}} src={isKannada? FinalLogoKannada : FinalLogoEnglish} alt="Cultural Tourism" />
                        </a>
                        </div>
                        <div
                            className={classes.navlinks}
                        >
                            <Link to="/" className={`${classes.link} ${location.pathname === '/' ? classes.activeLink : ''}`}>
                                {home}
                            </Link>
                            <div
                                className={classes.link}
                                aria-haspopup="true"
                                aria-controls="dest-menu"
                                onMouseOver={handleMenuOpen}
                                onMouseLeave={handleMenuMouseLeave}
                            >
                                {destination}
                                <Menu
                                    id="dest-menu"
                                    anchorEl={anchorEl}
                                    open={open && isMenuOpen}
                                    onClose={handleMenuClose}
                                    className={classes.dropdownMenu}
                                    PaperProps={{
                                        style: {
                                            maxHeight: 200,
                                            width: 250,
                                        },
                                    }}
                                >
                                    <MenuItem
                                        onClick={handleMenuSelect}
                                        component={Link}
                                        to="/destination/category?search=spiritual"
                                        className={classes.mobileNavLinks}
                                    >
                                        {spiritual}
                                    </MenuItem>
                                    <MenuItem
                                        onClick={handleMenuSelect}
                                        component={Link}
                                        to="/destination/category?search=historical"
                                        className={classes.mobileNavLinks}
                                    >
                                        {historical}
                                    </MenuItem>
                                    <MenuItem
                                        onClick={handleMenuSelect}
                                        component={Link}
                                        to="/destination/category?search=natural"
                                        className={classes.mobileNavLinks}
                                    >
                                        {natural}
                                    </MenuItem>
                                    <MenuItem
                                        onClick={handleMenuSelect}
                                        component={Link}
                                        to="/destination/category?search=other"
                                        className={classes.mobileNavLinks}
                                    >
                                        {other}
                                    </MenuItem>
                                </Menu>
                            </div>
                            <Link to="/artsAndCusine" className={`${classes.link} ${location.pathname === '/artsAndCusine' ? classes.activeLink : ''}`}>
                                {artsAndCuisine}
                            </Link>
                            <Link to="/festivals" className={`${classes.link} ${location.pathname === '/festivals' ? classes.activeLink : ''}`}>
                                {festivals}
                            </Link>
                            <Link to="/gallery" className={`${classes.link} ${location.pathname === '/gallery' ? classes.activeLink : ''}`}>
                                {gallery}
                            </Link>
                            <Link to="/aboutUs" className={`${classes.link} ${location.pathname === '/aboutUs' ? classes.activeLink : ''}`}>
                                {aboutUs}
                            </Link>
                        </div>
                    </>
                )}
                <div className={classes.languageSwitch}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isKannada}
                                onChange={toggleLanguage}
                                color="primary"
                            />
                        }
                        label=""
                    />
                    <Typography variant="body1" className={classes.languageLabel} style={{ color: 'black' }}>
                        <b>{kannada}</b>
                    </Typography>
                </div>
                {!isMobile &&
                    <div className={classes.logo}>
                        <a href="https://karnatakatourism.org" target="_blank" rel="noopener noreferrer">
                            <img style={{ height: '70px', width: '160px', paddingTop: '10px', marginLeft: '20px', marginRight: '20px', objectFit: 'cover'}} src={KarnatakaTourismLogo} alt="Karnataka Tourism" />
                        </a>
                    </div>
                }   
            </Toolbar>
            <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={handleDrawerToggle}
                classes={{ paper: classes.drawerPaper }}
            >
                <div className={classes.drawerContainer}>
                    <List>
                        <ListItem button component={Link} to="/" onClick={handleDrawerToggle}>
                            <ListItemText primary="Home" className={classes.mobileNavLinks} />
                        </ListItem>
                        <Divider />
                        <ListItem
                            button
                            component={Link} to="/destination" onClick={handleDrawerToggle}
                        >
                            <ListItemText primary="Destination" />
                        </ListItem>
                        <Popover
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            onClose={handleDestinationClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            <div className={classes.popoverPaper}>
                                <MenuItem
                                    onClick={handleDestinationClose}
                                    component={Link}
                                    to="/destination/category?search=spiritual"
                                    className={classes.mobileNavLinks}
                                >
                                    {spiritual}
                                </MenuItem>
                                <MenuItem
                                    onClick={handleDestinationClose}
                                    component={Link}
                                    to="/destination/category?search=historical"
                                    className={classes.mobileNavLinks}
                                >
                                    {historical}
                                </MenuItem>
                                <MenuItem
                                    onClick={handleDestinationClose}
                                    component={Link}
                                    to="/destination/category?search=natural"
                                    className={classes.mobileNavLinks}
                                >
                                    {natural}
                                </MenuItem>
                                <MenuItem
                                    onClick={handleDestinationClose}
                                    component={Link}
                                    to="/destination/category?search=other"
                                    className={classes.mobileNavLinks}
                                >
                                    {other}
                                </MenuItem>
                            </div>
                        </Popover>
                        <Divider />
                        <ListItem button component={Link} to="/festivals" onClick={handleDrawerToggle}>
                            <ListItemText primary="Festivals" className={classes.mobileNavLinks} />
                        </ListItem>
                        <Divider />
                        <ListItem button component={Link} to="/artsAndCusine" onClick={handleDrawerToggle}>
                            <ListItemText primary="Arts & Cuisine" className={classes.mobileNavLinks} />
                        </ListItem>
                        <Divider />
                        <ListItem button component={Link} to="/gallery" onClick={handleDrawerToggle}>
                            <ListItemText primary="Gallery" className={classes.mobileNavLinks} />
                        </ListItem>
                        <Divider />
                        <ListItem button component={Link} to="/aboutUs" onClick={handleDrawerToggle}>
                            <ListItemText primary="About Us" className={classes.mobileNavLinks} />
                        </ListItem>
                    </List>
                </div>
            </Drawer>
        </AppBar>
    );
}

export default Navbar;
